import { Column } from 'primereact/column'
import { DataTable, DataTableExpandedRows } from 'primereact/datatable'
import { FC, useState } from 'react'
import { RunStatus, WorkflowTask, WorkflowTaskStatus } from '../../types/types'
import { CloseIcon, CollapseIcon, ExpandIcon, RedoIcon, TimerOffIcon } from '../Icons'
import { CompactTableHeaderClassName, WorkflowStatusDef } from '../../utils/constants'
import { classNames } from 'primereact/utils'
import { RunStatusCircle } from './StatusCircle'
import IconButton from '../buttons/IconButton'
import { useRecommitRun, useCancelTask, useTimeoutTask } from '../../api/submission-API'
import { toast } from 'react-hot-toast'
import { confirmDialog } from 'primereact/confirmdialog'

type TaskListProp = {
  runId: string,
  workflowRunGroupSysId: string,
  workflowSystemRunId: string,
  tasks: any[]
}

const TaskList: FC<TaskListProp> = ({ runId, workflowRunGroupSysId, workflowSystemRunId, tasks }: TaskListProp) => {
  const [{ loading: submittingTask }, resubmitTask] = useRecommitRun()
  const [{ loading: cancellingTask }, cancelTask] = useCancelTask()
  const [{ loading: timingOutTask }, timeoutTask] = useTimeoutTask()
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({})

  const handleResubmit = async (taskId: string) => {
    if (submittingTask) {
      return
    }
    try {
      await resubmitTask({
        runId,
        workflowRunGroupSysId,
        workflowSystemRunId,
        taskId
      } as any)
      toast.success('Task resubmitted.')
    } catch (error) {
      toast.error('Failed to resubmit task.')
      console.log(error)
    }
  }

  const handleCancelTask = async (taskId: string) => {
    if (submittingTask) {
      return
    }
    try {
      await cancelTask({
        runId,
        workflowRunGroupSysId,
        workflowSystemRunId,
        taskId
      } as any)
      toast.success('Task cancelled.')
    } catch (error) {
      toast.error('Failed to cancel task.')
      console.log(error)
    }
  }

  const handleTimeout = async (gmRefString: string) => {
    if (timingOutTask) {
      return
    }
    try {
      await timeoutTask({
        gmRefString
      } as any)
      toast.success('Task timed out.')
    } catch (error) {
      toast.error('Failed to timeout task.')
      console.log(error)
    }
  }

  const columns = [
    {
      header: 'Task ID',
      field: '_id',
      headerClassName: CompactTableHeaderClassName,
      body: (rowData: WorkflowTask) => {
        const rowName: string = rowData.systemRunId

        return (
          <div
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => {
              // get current row id => if in expandRows, remove; else, append
              const copyExpandedRows: DataTableExpandedRows = { ...expandedRows }
              if (expandedRows[rowName]) {  // if rowName exists in expandedRows
                // delete rowName from expandedRows
                delete copyExpandedRows[rowName]
              } else { // if rowName doesnt exist, add rowName to expandedRows
                copyExpandedRows[rowName] = true
              }
              setExpandedRows(copyExpandedRows)
            }}>
            {
              expandedRows[rowName] ?
                <CollapseIcon className="fill-dark-blue dark:fill-light-blue h-6 w-6" /> :
                <ExpandIcon className="fill-dark-blue dark:fill-light-blue h-6 w-6" />
            }
            <span className="text-gm-blue dark:text-light-blue">{rowData.systemRunId}</span>
          </div>
        )
      }
    },
    {
      header: 'Name',
      field: 'name',
      headerClassName: CompactTableHeaderClassName,
      body: (rowData: WorkflowTask) => {
        if (rowData.name) {
          return rowData.name
        } else if (rowData.platformJob) {
          return rowData.platformJob.name
        } else if (rowData.rescaleJob) {
          return rowData.rescaleJob.operation
        } else {
          return ''
        }
      }
    },
    {
      header: 'Description',
      field: 'description',
      headerClassName: CompactTableHeaderClassName,
      body: (rowData: WorkflowTask) => {
        if (rowData.platformJob) {
          return rowData.platformJob.description
        } else {
          return rowData.rescaleJob && rowData.rescaleJob.name
        }
      }
    },
    {
      header: 'Type',
      // field: 'platformJob.type',
      headerClassName: classNames(CompactTableHeaderClassName, 'w-24'),
      body: (rowData: WorkflowTask) => {
        if (rowData.platformJob) {
          return 'Platform'
        } else if (rowData.rescaleJob) {
          return 'Rescale'
        } else {
          return ''
        }
      }
    },
    {
      header: 'Status',
      field: 'status',
      headerClassName: CompactTableHeaderClassName,
      body: (rowData: WorkflowTask) => {
        const status: RunStatus = rowData.status?.runStatus
        return (
          <div className="flex items-center">
            <RunStatusCircle status={status} />
            <span className='mr-4 flex items-center align-middle'>{WorkflowStatusDef[status]?.name}</span>
            {status === RunStatus.ERROR &&
              <IconButton
                title="Resubmit"
                severity="danger"
                onClick={() => handleResubmit(rowData.systemRunId)}
                loading={submittingTask}
                icon={<RedoIcon className="fill-white w-4 h-4" />}
              />}
            {
              (status !== RunStatus.COMPLETED && status !== RunStatus.RUNNING) &&
              <IconButton
                title="Cancel"
                severity="danger"
                onClick={() => handleCancelTask(rowData.systemRunId)}
                loading={cancellingTask}
                icon={<CloseIcon className="fill-white w-4 h-4" />}
              />
            }
            {
              status === RunStatus.RUNNING &&
              <IconButton
                title="Timeout Task"
                severity="danger"
                onClick={() => {
                  confirmDialog({
                    message: `Are you sure you want to timeout this task? This action is meant for hung tasks in the RUNNING state.`,
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    defaultFocus: 'reject',
                    rejectClassName: 'bg-gray-500 text-white',
                    accept: () => {
                      handleTimeout(rowData.gmRefString)
                    }
                  })
                }}
                loading={timingOutTask}
                icon={<TimerOffIcon className="fill-white w-4 h-4" />}
              />
            }
          </div>
        )
      }
    },
    {
      header: 'Active',
      field: 'active',
      headerClassName: classNames(CompactTableHeaderClassName, 'w-20'),
      body: (rowData: WorkflowTask) => {
        if (rowData.platformJob) {
          return rowData.platformJob.active ? 'Yes' : 'No'
        } else {
          return ''
        }
      }
    }
  ]

  return (
    <DataTable
      value={tasks}
      dataKey="systemRunId"
      pt={{
        root: {
          className: "mb-4"
        },
        bodyRow: {
          className: 'table-row'
        }
      }}
      expandedRows={expandedRows}
      rowExpansionTemplate={(rowData: WorkflowTask) => {
        let platformJob
        let rescaleJob
        let gmRefString = rowData?.gmRefString || ''
        let warnOnException = rowData?.warnOnException

        if (rowData.platformJob && rowData.status) {
          platformJob = {
            ...rowData.platformJob,
            status: rowData.status,
          }
        }
        if (rowData.rescaleJob && rowData.status) {
          rescaleJob = {
            ...rowData.rescaleJob,
            status: rowData.status
          }
        }

        return (
          <>
            {gmRefString && (
              <div className="pl-8 max-w-[100%]">
                <pre className="whitespace-pre-wrap">{JSON.stringify(gmRefString, null, 2)}</pre>
              </div>
            )}
            {warnOnException && (
              <div className="pl-8 max-w-[100%]">
                <pre className="whitespace-pre-wrap">{JSON.stringify(warnOnException, null, 2)}</pre>
              </div>
            )}
            {platformJob && (
              <div className="pl-8 max-w-[100%]">
                <pre className="whitespace-pre-wrap">{JSON.stringify(platformJob, null, 2)}</pre>
              </div>
            )}
            {rescaleJob && (
              <div className="pl-8 max-w-[100%]">
                <pre className="whitespace-pre-wrap">{JSON.stringify(rescaleJob, null, 2)}</pre>
              </div>
            )}
          </>
        )
      }
      }

    >
      {columns.map((item) => {
        return (
          <Column
            key={item.header}
            field={item.field}
            header={item.header}
            headerClassName={item.headerClassName}
            body={item.body}
          />
        )
      })}

    </DataTable>
  )
}

export default TaskList
