import { UseAxiosResult } from 'axios-hooks'
import { useAxiosClient } from './configs/axios-setup'

export interface IRequestError {
  status: number
  text: string
}

export const useGetPtapLocationSets = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/PtapLocationSets',
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (query) => {
    return doFetch({
      url: '/PtapLocationSets' + (query ? `?${query}` : '')
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useGetPtapLocationSet = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (id: string) => {
    return doFetch({
      url: `/PtapLocationSets/${id}`
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}
