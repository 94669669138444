import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser'
import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import App from './App'
import { msalConfig } from './authConfig'
import { accessTokenRequest } from './pages/Login'
import reportWebVitals from './reportWebVitals'
import './styles/index.css'
import { extractTempQuery, setQueryId } from './utils/global_shared_tempURL'

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const msalInstance: IPublicClientApplication = new PublicClientApplication(msalConfig)

msalInstance.initialize().then(() => {
  // Register Callbacks for Redirect flow
  msalInstance
    .handleRedirectPromise()
    .then((redirectResp) => {
      console.log('MSAL Redirect Response: ', redirectResp)
      if (redirectResp !== null) {
        localStorage.setItem('ACCESS_TOKEN', redirectResp.accessToken)
        msalInstance.setActiveAccount(redirectResp.account)
      } else {
        // need to call getAccount here?
        const currentAccounts = msalInstance.getAllAccounts()
        if (!currentAccounts || currentAccounts.length < 1) {
          console.log('Need to login>>>')
          return msalInstance.loginRedirect(accessTokenRequest)
        }
      }
    })
    .catch((err) => {
      console.error('MSAL Redirect Error: ', err)
    })

  /*
  See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
  Need to acquire each API token in a seperate call.  Commenting the graph API for now as not needed.
   */

  root.render(
    <RecoilRoot>
      <HashRouter>
        <Suspense fallback={<div>Loading CFD Application...</div>}>
          <App pca={msalInstance} />
        </Suspense>
      </HashRouter>
    </RecoilRoot>
  )
})

reportWebVitals()
