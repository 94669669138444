interface IRoutePath {
  relativePath: string
  absolutePath: string
}

interface IRoutes {
  Home: IRoutePath
  Vehicles: IRoutePath
  Projects: IRoutePath
  NewProject: IRoutePath
  EditProject: IRoutePath
  NewSubmission: IRoutePath
  EditSubmission: IRoutePath
  Login: IRoutePath
  Profile: IRoutePath
  DataOverview: IRoutePath
  Register: IRoutePath
  MethodologySets: IRoutePath
  AttitudeSets: IRoutePath
  TyreSets: IRoutePath
  DomainSets: IRoutePath
  SimulationSets: IRoutePath
  ProjectConfigs: IRoutePath
  CFDSequences: IRoutePath
  Workflows: IRoutePath
  WorkflowTemplates: IRoutePath
  VehicleStates: IRoutePath
  Submission: IRoutePath
  Submissions: IRoutePath
  Geometries: IRoutePath
  Sequences: IRoutePath
  RescaleQueue: IRoutePath
  HeadlineData: IRoutePath
  NotFound: IRoutePath
}

const routes: IRoutes = {
  Home: { relativePath: '/', absolutePath: '/' },
  Projects: { relativePath: 'projects', absolutePath: '/projects' },
  DataOverview: { relativePath: 'data-overview', absolutePath: '/data-overview' },
  MethodologySets: { relativePath: 'methodologies', absolutePath: '/methodologies' },
  TyreSets: { relativePath: 'tyre-sets', absolutePath: '/tyre-sets' },
  DomainSets: { relativePath: 'domain-sets', absolutePath: '/domain-sets' },
  AttitudeSets: { relativePath: 'attitude-sets', absolutePath: '/attitude-sets' },
  SimulationSets: { relativePath: 'simulation-sets', absolutePath: '/simulation-sets' },
  ProjectConfigs: { relativePath: 'project-configs', absolutePath: '/project-configs' },
  Workflows: { relativePath: 'workflows', absolutePath: '/workflows' },
  VehicleStates: { relativePath: 'vehicle-states', absolutePath: '/vehicle-states' },
  Vehicles: { relativePath: 'vehicles', absolutePath: '/vehicles' },
  Geometries: { relativePath: 'geometries', absolutePath: '/geometries' },
  Sequences: { relativePath: 'sequences', absolutePath: '/sequences' },
  CFDSequences: { relativePath: 'cfd-sequences', absolutePath: '/cfd-sequences' },
  Login: { relativePath: 'login', absolutePath: '/auth/login' },
  Register: { relativePath: 'register', absolutePath: '/auth/register' },
  Profile: { relativePath: 'profile', absolutePath: '/profile' },
  NewProject: { relativePath: 'new-project', absolutePath: '/new-project' },
  EditProject: { relativePath: 'projects/:id', absolutePath: '/projects/:id' },
  NewSubmission: { relativePath: 'new-submission', absolutePath: '/new-submission' },
  EditSubmission: { relativePath: 'edit-submission/:id', absolutePath: '/edit-submission/:id' },
  Submissions: { relativePath: 'submissions', absolutePath: '/submissions' },
  Submission: { relativePath: 'submissions/:id', absolutePath: '/submissions/:id' },
  WorkflowTemplates: { relativePath: 'workflow-templates', absolutePath: '/workflow-templates' },
  RescaleQueue: { relativePath: 'rescale-queue', absolutePath: '/rescale-queue' },
  HeadlineData: { relativePath: 'headline-data', absolutePath: '/headline-data' },
  NotFound: { relativePath: 'not-found', absolutePath: '/not-found' }
}

export default routes
