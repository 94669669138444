import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import {
  DataTable,
  DataTableFilterMeta,
  DataTableSortEvent,
  SortOrder
} from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { Nullable } from 'primereact/ts-helpers'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useGetSubmissions } from '../api/submission-API'
import { ReactComponent as ViewIcon } from '../assets/svg/eye.svg'
import SubmissionStatusCell from '../components/submission/SubmissionStatusCell'
import { protoService } from '../proto/ProtoService'
import {
  LazyTableState,
  Project,
  RunStatus,
  WorkflowSubmission
} from '../types/types'

import { useGetProjects } from '../api/project-API'
import { queryString2JsonString } from '../types/util'
import { SubmissionStatusList } from '../utils/constants'
import { formatDateWithLocale } from '../utils/intl'
import { currentUserState, vehicleListState } from '../utils/state-atoms'
import { CheckboxStyle } from '../utils/styling-constants'
import IconButton from '../components/buttons/IconButton'
import { CloseIcon, FilterIcon } from '../components/Icons'

const SUBMISSION_FILTER_KEY = 'SUBMISSION_FILTER'

const projection = encodeURIComponent(
  JSON.stringify({
    _id: 1,
    name: 1,
    shortName: 1,
    description: 1,
    overallStatus: 1,
    timeCreated: 1,
    createByUserName: 1,
    projectId: 1,
    projectName: 1,
    submitUser: 1,
    ['workflowRunGroups.systemRunId']: 1,
    ['runConfig.description']: 1,
    ['runConfig.referenceRunName']: 1,
    ['runConfig.baselineRunName']: 1,
  })
)

type SubmissionFilter = {
  vehicle?: string
  project?: string
  byMeOnly?: string
  status?: string
  engineer?: string
  startDate?: Date
  endDate?: Date
}

const defaultFilters: DataTableFilterMeta = {
  vehicle: { value: undefined, matchMode: 'equals' },
  project: { value: undefined, matchMode: 'equals' },
  byMeOnly: { value: false, matchMode: 'equals' },
  status: { value: undefined, matchMode: 'equals' },
  engineer: { value: undefined, matchMode: 'contains' },
  startDate: { value: undefined, matchMode: 'dateAfter' },
  endDate: { value: undefined, matchMode: 'dateBefore' }
}

const Submissions: FC = () => {
  const savedFilterString = localStorage.getItem(SUBMISSION_FILTER_KEY)
  let savedFilter: SubmissionFilter | null = null
  if (savedFilterString) {
    savedFilter = JSON.parse(savedFilterString) as SubmissionFilter
  }
  const stDate = savedFilter && savedFilter.startDate ? new Date(savedFilter.startDate) : null
  const enDate = savedFilter && savedFilter.endDate ? new Date(savedFilter.endDate) : null

  const navigate = useNavigate()

  const [byMeOnly, setByMeOnly] = useState(savedFilter ? (savedFilter.byMeOnly === 'true' || savedFilter.byMeOnly === null) : false)
  const [filterStatus, setFilterStatus] = useState<string | undefined>(savedFilter ? savedFilter.status : undefined)
  const [filterVehicle, setFilterVehicle] = useState<string | undefined>(savedFilter ? savedFilter.vehicle : undefined)
  const [filterProject, setFilterProject] = useState<string | undefined>(savedFilter ? savedFilter.project : undefined)
  const [filterEngineer, setFilterEngineer] = useState<string | undefined>(savedFilter ? savedFilter.engineer : undefined)
  const [filterDateRange, setFilterDateRange] = useState<Nullable<(Date | null)[]>>([stDate, enDate])

  const vehicleList = useRecoilValue(vehicleListState)
  const [projectList, setProjectList] = useState<Project[]>([])
  const [sortField, setSortField] = useState<string>('timeCreated')
  const [sortOrder, setSortOrder] = useState<SortOrder>(-1)
  const currentUser = useRecoilValue(currentUserState)

  const columns = [
    {
      header: 'ID',
      field: 'name',
      sortable: true,
      headerClassName: 'w-[200px]',
      bodyClassName: 'w-[200px] id-cell',
      body: (rowData) => {
        return <Link to={`/submissions/${rowData.name}`}>{rowData.name}</Link>
      }
    },
    {
      header: 'Project',
      field: 'project.name',
      sortable: true,
      headerClassName: 'w-[150px]',
      bodyClassName: 'w-[150px]',
      body: (rowData: WorkflowSubmission) => {
        return (
          <Link to={`/projects/${rowData.projectName}`} className="underline">
            {rowData.projectName}
          </Link>
        )
      }
    },
    {
      header: 'Date Created',
      field: 'timeCreated',
      sortable: true,
      body: (rowData) => {
        return <span>{formatDateWithLocale(0 + rowData.timeCreated, currentUser?.locale)}</span>
      }
    },
    {
      header: 'Submitted By',
      field: 'submitUser.firstName',
      sortable: true,
      body: (rowData: WorkflowSubmission) => {
        let name = ''
        if (rowData.submitUser) {
          if ((rowData.submitUser.firstName || rowData.submitUser.lastName)) {
            name = `${rowData.submitUser.firstName} ${rowData.submitUser.lastName}`
          } else {
            name = rowData.submitUser.email
          }
        }
        return (
          <span>{name}</span>
        )
      }
    },
    {
      header: 'Overall Status',
      field: 'overallStatus',
      sortable: true,
      body: (rowData: WorkflowSubmission) => {
        return (
          <SubmissionStatusCell status={rowData.overallStatus} />
        )
      }
    }
  ]

  const [{ data: submissionData, loading: laodingSubmissions, }, fetchSubmissions] = useGetSubmissions()
  const [filterOpen, setFilterOpen] = useState(
    savedFilter && (!!savedFilter.status || !!savedFilter.vehicle || !!savedFilter.project || !!stDate || !!enDate)
  )

  const toggleFilter = () => {
    setFilterOpen(!filterOpen)
  }

  const [submissions, setSubmissions] = useState<WorkflowSubmission[]>([])
  const [{ data: projectsData, loading: loadingProjects }, fetchProjects] = useGetProjects()
  const [lazyState, setlazyState] = useState<LazyTableState>({
    first: 0,
    page: 0,
    filters: {
      ...defaultFilters,
      vehicle: { value: filterVehicle, matchMode: 'equals' },
      project: { value: filterProject, matchMode: 'equals' },
      byMeOnly: { matchMode: 'equals', value: savedFilter?.byMeOnly === 'true' },
      engineer: { value: filterEngineer ? filterEngineer : '', matchMode: 'equals' },
      status: { value: filterStatus ? RunStatus[filterStatus] : '', matchMode: 'equals' },
      startDate: { value: filterDateRange ? filterDateRange[0] as Date : undefined, matchMode: 'dateAfter' },
      endDate: { value: filterDateRange ? filterDateRange[1] as Date : undefined, matchMode: 'dateBefore' }
    } as DataTableFilterMeta,
  } as LazyTableState)


  const handleClearFilter = () => {
    setFilterVehicle(undefined)
    setFilterProject(undefined)
    setFilterStatus(undefined)
    setFilterDateRange([])
    setFilterOpen(false)
  }

  const handleApplyFilter = () => {
    const filters: DataTableFilterMeta = { ...defaultFilters }
    if (filterDateRange) {
      if (filterDateRange[0]) {
        filters.startDate = { value: filterDateRange[0], matchMode: 'dateAfter' }
      }
      if (filterDateRange[1]) {
        filters.endDate = { value: filterDateRange[1], matchMode: 'dateBefore' }
      }
    }

    filters.project = { value: filterProject, matchMode: 'equals' }
    filters.status = { value: filterStatus ? RunStatus[filterStatus] : '', matchMode: 'equals' }
    if (filterEngineer) {
      filters.engineer = { value: filterEngineer, matchMode: 'equals' }
    }

    const filterToSave: SubmissionFilter = {
      vehicle: filterVehicle,
      project: filterProject,
      byMeOnly: byMeOnly ? 'true' : 'false',
      status: filterStatus,
      engineer: filterEngineer,
      startDate: filterDateRange ? filterDateRange[0] as Date : undefined,
      endDate: filterDateRange ? filterDateRange[1] as Date : undefined
    }
    localStorage.setItem(SUBMISSION_FILTER_KEY, JSON.stringify(filterToSave))

    setlazyState({
      ...lazyState,
      first: 0,
      page: 0,
      filters
    })
  }

  /*
   const filters: DataTableFilterMeta = { ...defaultFilters }
    if (filterDateRange) {
      if (filterDateRange[0]) {
        filters.startDate = { value: filterDateRange[0], matchMode: 'dateAfter' }
      }
      if (filterDateRange[1]) {
        filters.endDate = { value: filterDateRange[1], matchMode: 'dateBefore' }
      }
    }

    filters.project = { value: filterProject, matchMode: 'equals' }
    filters.status = { value: filterStatus ? RunStatus[filterStatus] : '', matchMode: 'equals' }
    if (filterEngineer) {
      filters.engineer = { value: filterEngineer, matchMode: 'equals' }
    }

  const [byMeOnly, setByMeOnly] = useState(savedFilter ? (savedFilter.byMeOnly === 'true' || savedFilter.byMeOnly === null) : false)
  const [filterStatus, setFilterStatus] = useState<string | undefined>(savedFilter ? savedFilter.status : undefined)
  const [filterVehicle, setFilterVehicle] = useState<string | undefined>(savedFilter ? savedFilter.vehicle : undefined)
  const [filterProject, setFilterProject] = useState<string | undefined>(savedFilter ? savedFilter.project : undefined)
  const [filterEngineer, setFilterEngineer] = useState<string | undefined>(savedFilter ? savedFilter.engineer : undefined)
  const [filterDateRange, setFilterDateRange] = useState<Nullable<(Date | null)[]>>([stDate, endDate])

  */

  useEffect(() => {
    let query
    if (lazyState.filters.engineer['value']) {
      query = `createByUser=${lazyState.filters.engineer['value']}`
    }

    if (lazyState.filters.project['value']) {
      const q = 'projectId=' + lazyState.filters.project['value']
      query = query ? `${query}&${q}` : q
    } else {
      if (filterVehicle) {
        const q = 'vehicleId=' + filterVehicle
        query = query ? `${query}&${q}` : q
      }
    }

    const dateRange = {}
    if (lazyState.filters.startDate['value']) {
      dateRange['$gte'] = (lazyState.filters.startDate['value'] as Date).getTime()
    }

    if (lazyState.filters.endDate['value']) {
      dateRange['$lte'] = (lazyState.filters.endDate['value'] as Date).getTime()
    }

    if (dateRange['$gte'] || dateRange['$lte']) {
      const dateRangeStr = JSON.stringify(dateRange)
      const q = 'timeCreated=' + encodeURIComponent(dateRangeStr)
      query = query ? `${query}&${q}` : q
    }

    if (lazyState.filters.status['value']) {
      const q = 'overallStatus=' + lazyState.filters.status['value']
      query = query ? `${query}&${q}` : q
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${lazyState.rows}` : `limit=${lazyState.rows}`
    query = `${query}&proj=${projection}&skip=${lazyState.first}&countTotal=true`
    if (sortField) {
      const sort = {
        [sortField]: sortOrder
      }
      query = `${query}&sort=${encodeURIComponent(JSON.stringify(sort))}`
    }

    fetchSubmissions(query).catch((err) => {
      console.error(err)
      toast.error('Error loading submissions.')
    })
  }, [lazyState])

  useEffect(() => {
    if (filterVehicle) {
      console.log(filterVehicle)
      const query = 'simple=true&query=' + queryString2JsonString(`vehicleId=${filterVehicle}`)
      fetchProjects(query).catch((err) => {
        console.error(err)
        toast.error('Error loading projects.')
      })
    }
  }, [filterVehicle])


  useEffect(() => {
    if (submissionData) {
      const subList = protoService.decodeProtoResponse(submissionData, 'workflowsubmission')
      console.log('===>', subList)
      setSubmissions(subList)
    }
  }, [submissionData])

  useEffect(() => {
    const projs =
      projectsData &&
      projectsData.protoBytesList.map((item: string) => {
        const proj = protoService.deSerialize(item, 'project')
        return proj
      })
    console.log(projs)
    projs && setProjectList(projs)
  }, [projectsData])

  const handleShowMeOnlyChange = (evt: CheckboxChangeEvent) => {
    const eng = evt.checked ? currentUser?._id as string : ''
    setFilterEngineer(eng)
    setByMeOnly(evt.checked as boolean)

    const filterToSave: SubmissionFilter = {
      vehicle: filterVehicle,
      project: filterProject,
      byMeOnly: evt.checked ? 'true' : 'false',
      status: filterStatus,
      engineer: eng,
      startDate: filterDateRange ? filterDateRange[0] as Date : undefined,
      endDate: filterDateRange ? filterDateRange[1] as Date : undefined
    }
    localStorage.setItem(SUBMISSION_FILTER_KEY, JSON.stringify(filterToSave))

    setlazyState({
      ...lazyState,
      first: 0,
      page: 0,
      filters: {
        ...lazyState.filters,
        engineer: { value: evt.checked ? currentUser?._id as string : '', matchMode: 'equals' },
        byMeOnly: { value: evt.checked, matchMode: 'equals' }
      }
    })
  }

  const handleViewSubmission = (submission: WorkflowSubmission) => {
    navigate(`/submissions/${submission._id}`)
  }

  const handleSort = (evt: DataTableSortEvent) => {
    setSortField(evt.sortField)
    setSortOrder(evt.sortOrder)
  }

  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full">
      <div className="basis-0 flex items-center justify-between">
        <div className="flex items-center space-x-2 font-bold ">
          <div className="page-title">Submissions</div>
        </div>
        <div className="flex items-center justify-end space-x-2">
          <div className="flex items-center">
            <Checkbox
              inputId="byMeOnly"
              pt={CheckboxStyle}
              onChange={handleShowMeOnlyChange}
              checked={byMeOnly}></Checkbox>
            <label htmlFor="byMeOnly" className="ml-2 font-normal">
              Submitted by me only
            </label>
          </div>
          <IconButton title="Show/Hide Filter" onClick={toggleFilter} severity="secondary" icon={<FilterIcon className="fill-white" />} />
        </div>
      </div>
      {/* filter block begins */}
      {filterOpen && (
        <div className="flex items-end p-4 space-x-2 bg-gray-100 mt-4 animate-fade-in-down">
          <div className="flex flex-col items-stretch basis-0 grow space-y-2">
            <label>Car</label>
            <Dropdown
              value={filterVehicle}
              showClear
              pt={{
                root: {
                  className: 'min-w-full'
                },
                filterInput: {
                  className: '!py-1'
                },
                input: {
                  className: '!py-2'
                }
              }}
              onChange={(e) => {
                console.log(e)
                setFilterVehicle(e.value)
                setFilterProject(undefined)
              }}
              placeholder="Select a Car"
              filter
              options={vehicleList}
              optionValue="_id"
              optionLabel="name"
              emptyMessage="&nbsp;&nbsp;No data"
            />
          </div>
          <div className="flex flex-col items-stretch basis-0 grow space-y-2">
            <label>Project</label>
            <Dropdown
              pt={{
                root: {
                  className: 'min-w-full'
                },
                filterInput: {
                  className: '!py-1'
                },
                input: {
                  className: '!py-2'
                }
              }}
              onChange={(evt) => {
                setFilterProject(evt.value)
              }}
              showClear
              filter
              loading={loadingProjects}
              filterBy="name"
              value={filterProject}
              options={projectList}
              optionLabel="name"
              optionValue="_id"
              placeholder="Select Project"
              itemTemplate={(item) => {
                return `${item.name}`
              }}
            />
          </div>
          <div className="flex flex-col items-stretch basis-0 grow space-y-2">
            <label>Submission Date Range</label>
            <Calendar
              pt={{
                root: {
                  className: ''
                },
                input: {
                  root: {
                    className: 'basis-0 grow'
                  }
                },
                buttonbar: {
                  className: 'flex justify-between px-6 pb-4'
                },
                panel: {
                  className: 'max-w-[500px]'
                },
                dropdownButton: {
                  root: {
                    className:
                      'bg-white rounded-bl-none rounded-tl-none border-l-0 border-gray-300 text-gray-500'
                  },
                  icon: {
                    className: 'fill-gray-500 pi-red text-red-200'
                  }
                }
              }}
              showButtonBar
              value={filterDateRange}
              onChange={(evt) => {
                console.log(evt)
                setFilterDateRange(evt.value)
              }}
              selectionMode="range"
              placeholder="Select Date Range"
              readOnlyInput
            />
          </div>
          <div className="flex flex-col items-stretch basis-0 grow space-y-2">
            <label>Status</label>
            <Dropdown
              pt={{
                root: {
                  className: 'min-w-full'
                },
                input: {
                  className: '!py-2'
                }
              }}
              onChange={(evt) => {
                console.log(evt.value)
                setFilterStatus(evt.value)
              }}
              value={filterStatus}
              options={SubmissionStatusList}
              showClear
              placeholder="Select Status"
              optionValue="value"
              optionLabel="name"
            />
          </div>
          <Button onClick={handleApplyFilter} className='icon-button' tooltip='Apply Filter' tooltipOptions={{ position: "bottom" }} data-pr-at='center+15 bottom'>
            <FilterIcon className='fill-white' />
          </Button>
          <Button onClick={handleClearFilter} className="dark-button icon-button" tooltip='Clear Filter' tooltipOptions={{ position: "bottom" }} data-pr-at='center+15 bottom'>
            <CloseIcon className="fill-white h-4 w-4" />
          </Button>
        </div>
      )}
      {/* filter block ends */}

      <DataTable
        value={submissions}
        first={lazyState.first}
        loading={laodingSubmissions}
        sortField={sortField}
        sortOrder={sortOrder}
        dataKey="_id"
        stripedRows
        rows={lazyState.rows}
        onSort={handleSort}
        pt={{
          root: {
            className: 'mt-8'
          },
          bodyRow: {
            className: 'table-row'
          },
        }}>
        {columns.map((item) => {
          return (
            <Column
              key={item.field}
              field={item.field}
              header={item.header}
              body={item.body}
              sortable={item.sortable}
              headerClassName={item.headerClassName}
              bodyClassName={item.bodyClassName}></Column>
          )
        })}
        <Column
          body={(rowData, options) => {
            return (
              <div className="flex items-center justify-center">
                <IconButton title="View" onClick={() => handleViewSubmission(rowData)} severity="secondary"
                  icon={<ViewIcon className="fill-white h-4 w-4" />} />
              </div>
            )
          }}
          headerClassName="one-col-editor-header editor-header"
          headerStyle={{ width: '50px' }}
          pt={{
            root: {
              className: 'px-2 py-2 text-center'
            }
          }}></Column>
      </DataTable>
    </div>
  )
}

export default Submissions
