import { Button } from "primereact/button"
import { Panel } from "primereact/panel"
import { useRef, useState } from "react"
import { CollapseIcon, CopyIcon, EditIcon, ExpandIcon } from "../Icons"
import IconButton from "../buttons/IconButton"
import { formatDateWithLocale } from "../../utils/intl"
import { WorkflowStatusDef } from "../../utils/constants"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { RunStatusCircle } from "./StatusCircle"
import { RunStatus } from "@aero-platform/shared/generated/AeroStructures"
import { toast } from "react-hot-toast"
import { classNames } from "primereact/utils"
import { currentUserState } from "../../utils/state-atoms"
import { useRecoilValue } from "recoil"

const columns = [
  {
    header: 'Name',
    field: 'name',
    headerClassName: "",
    bodyClassName: 'font-bold',
    body: (rowData) => {
      return (
        <div className="flex items-center space-x-2 cursor-pointer">
          <>{rowData.partRef.name}</>
        </div>
      )
    }
  },
  {
    header: 'PID',
    field: 'partRef.pid'
  },
  {
    header: 'Type',
    field: 'partRef.type'
  },
]


const RunDetailsPanel = ({ submission, setShowEditModal }) => {
  const refDetails = useRef<Panel>(null)
  const [showDetails, setShowDetails] = useState(false)
  const [showCopy, setShowCopy] = useState(false)
  const currentUser = useRecoilValue(currentUserState)

  return (
    <Panel
      ref={refDetails}
      toggleable
      collapsed={!showDetails}
      header={
        <div className="flex items-center space-x-1 min-w-full">
          <Button
            link
            className="icon-button bg-transparent"
            onClick={(evt) => {
              refDetails.current?.toggle(evt)
            }}>
            {!showDetails && <ExpandIcon className="fill-dark-blue dark:fill-neutral-10 h-6 w-6" />}
            {showDetails && <CollapseIcon className="fill-dark-blue dark:fill-neutral-10 h-6 w-6" />}
          </Button>
          <span className="text-gm-primary-90 dark:text-light-blue text-lg">Run Details</span>
          <div className="text-gm-primary-90 dark:text-light-blue text-lg">-</div>
          <div className="flex items-center space-x-1"
            onMouseEnter={() => setShowCopy(true)}
            onMouseLeave={() => setShowCopy(false)}
          >
            <span
              className="text-gm-primary-90 dark:text-light-blue hover:text-gm-blue text-lg"
            >{submission.name}</span>
            {showCopy &&
              <IconButton clear title="Copy" icon={<CopyIcon className="fill-gm-blue w-4 h-4" />}
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(submission.name)
                    toast.success('Run ID copied to clipboard.')
                  } catch (err) {
                    toast.error('Failed to copy.')
                  }
                }}></IconButton>}
          </div>
          <div className="flex items-center space-x-1 justify-end grow pr-2">
            {submission && submission.overallStatus === RunStatus.CREATED && showDetails &&
              <IconButton
                title="Edit"
                severity="secondary"
                icon={<EditIcon className="fill-neutral-10 dark:fill-neutral-10 h-4 w-4" />}
                onClick={() => setShowEditModal(true)}>
              </IconButton>}
          </div>
        </div>
      }
      onExpand={(e) => setShowDetails(true)}
      onCollapse={(e) => setShowDetails(false)}
      pt={{
        root: {
          className: 'rounded-lg shadow-md dark:border-neutral-90'
        },
        toggler: {
          className: 'hidden'
        },
        header: {
          className: classNames('bg-alt-gray dark:bg-neutral-80 dark:border-neutral-90 !p-2', {
            'rounded-b-none': showDetails,
            'rounded-b-md': !showDetails
          })
        },
        title: {
          className: 'grow'
        },
        content: { className: 'p-4 dark:bg-neutral-80 dark:border-neutral-90' }
      }}>

      <div className=" grid grid-cols-2 ">
        <div className="grid grid-cols-2 gap-y-4 auto-rows-min">

          <div className="col-span-2">
            <div className="font-bold">Description</div>
            <div>{submission.runConfig.description}</div>
          </div>

          <div className="grid grid-flow-col justify-start">
            <div className="flex justify-center items-center ">  {/* border-solid border-2 border-black*/}
              <RunStatusCircle status={submission.overallStatus} />
            </div>
            <div className="flex flex-col ">
              <div className="font-bold">Status</div>
              <div>{WorkflowStatusDef[submission.overallStatus].name}</div>
            </div>
          </div>

          <div className="">
            <div className="font-bold">RATG</div>
            <div>{submission.runConfig.RATGstatus}</div>
          </div>

          <div className="">
            <div className="font-bold">Methodology Set</div>
            <div>{submission.runConfig.selectedMethodSet?.name}</div>
          </div>

          <div className="">
            <div className="font-bold">Master Geometry</div>
            <div>{submission.runConfig.baselineRunName || 'None'}</div>
          </div>

          <div className="">
            <div className="font-bold">Reference Run</div>
            <div>{submission.runConfig.referenceRunName || 'None'}</div>
          </div>

          <div className="">
            <div className="font-bold">Attitude Set</div>
            <div>{submission.runConfig.selectedAttitudeSet?.name || 'N/A'}</div>
          </div>

          <div className="">
            <div className="font-bold">Trajectory</div>
            <div>{submission.runConfig.selectedTrajectory?.name || 'N/A'}</div>
          </div>

          <div className="">
            <div className="font-bold">Domain Set</div>
            <div>{submission.runConfig.selectedDomainSet?.name || 'N/A'}</div>
          </div>

          <div className="">
            <div className="font-bold">Tyre Set</div>
            <div>{submission.runConfig.selectedTyreSet?.name || 'N/A'}</div>
          </div>

          <div className="">
            <div className="font-bold">Simulation Set</div>
            <div>{submission.runConfig.selectedSimulationSet?.name || 'N/A'}</div>
          </div>

          <div className="">
            <div className="font-bold">Submission Template</div>
            <div>{submission.runConfig.workflowSubmissionTemplate?.name || 'N/A'}</div>
          </div>

          <div className="">
            <div className="font-bold">Geometry</div>
            <div>{submission.runConfig.geoStagingDir}</div>
          </div>

          <div>
            <div className="font-bold">Pressure Tap Files</div>
            <div>{(submission.runConfig.selectedPtapLocationSet?.name + '/' + submission.runConfig.selectedPtapLocationSet?.version) || 'N/A'}</div>
          </div>

          <div>
            <div className="font-bold">Ride Height Files</div>
            <div>{(submission.runConfig.selectedRHRepoSet?.name + '/' + submission.runConfig.selectedRHRepoSet?.version) || 'N/A'}</div>
          </div>

          <div className="">
            <div className="font-bold">Created by</div>
            <div>{submission.createByUserName}</div>
          </div>

          <div className="">
            <div className="font-bold">Date Created</div>
            <div>{formatDateWithLocale(0 + submission.timeCreated, currentUser.locale)}</div>
          </div>
        </div>

        <div>
          <DataTable
            value={submission.cfdPartsList?.parts || []}
            dataKey="partRef.name"
            pt={{
              bodyRow: {
                className: 'table-row'
              }
            }}>
            {columns.map((item) => {
              return (
                <Column
                  key={item.field}
                  field={item.field}
                  header={item.header}
                  headerClassName={item.headerClassName}
                  bodyClassName={item.bodyClassName}
                  body={item.body}
                />
              )
            })}
          </DataTable>
        </div>
      </div>
    </Panel>
  )
}

export default RunDetailsPanel
