import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { FC, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useGetSubmissions } from '../../api/submission-API'
import { protoService } from '../../proto/ProtoService'
import {
  Project,
  ProjectStatus,
  AttitudeSet,
  DomainSet,
  TyreSet,
  SimulationSet,
  MethodologySet,
  WorkflowSubmission,
  WorkflowSubmissionTemplate
} from '../../types/types'
import { queryString2JsonString } from '../../types/util'
import { Areas, ProjectStatusList } from '../../utils/constants'
import {
  attitudeSetListState,
  domainSetListState,
  simulationSetListState,
  tyreSetListState,
  currentUserState,
  engineerListState,
  lastTenRunsState,
  projectConfigListState,
  workflowTemplateListState,
  methodologySetListState,
  isF1TeamState
} from '../../utils/state-atoms'
import { CheckboxStyle, DropdownStyle } from '../../utils/styling-constants'
import SaveButton from '../buttons/SaveButton'
import { Button } from 'primereact/button'
import { CopyIcon } from '../Icons'
import { useGetProjects } from '../../api/project-API'

type ProjectFormProps = {
  saving: boolean
  mode: string
  initProject: Project
  onSave?: (data: Project) => void
  onReopen?: () => void,
}

const ProjectForm: FC<ProjectFormProps> = ({ saving, mode, initProject, onSave, onReopen }) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<Project>()
  const currentUser = useRecoilValue(currentUserState)
  const [baselineRequired, setBaselineRequired] = useState(false)
  const [projectConfigList] = useRecoilState(projectConfigListState)
  const attitudeSets = useRecoilValue(attitudeSetListState)
  const domainSets = useRecoilValue(domainSetListState)
  const tyreSets = useRecoilValue(tyreSetListState)
  const simulationSets = useRecoilValue(simulationSetListState)
  const methodologySets = useRecoilValue(methodologySetListState)
  const templates = useRecoilValue(workflowTemplateListState)
  const engineers = useRecoilValue(engineerListState)
  const isF1Team = useRecoilValue(isF1TeamState)
  const latestSubmissions = useRecoilValue(lastTenRunsState)
  const [templateList, setTemplateList] = useState<WorkflowSubmissionTemplate[]>(templates)
  const [attitudeSetList, setAttitudeSetList] = useState<AttitudeSet[]>(attitudeSets || [])
  const [methodologySetList, setMethodologySetList] = useState<MethodologySet[]>(methodologySets || [])
  const [domainSetList, setDomainSetList] = useState<DomainSet[]>(domainSets || [])
  const [tyreSetList, setTyreSetSetList] = useState<TyreSet[]>(tyreSets || [])
  const [simulationSetList, setSimulationSetList] = useState<SimulationSet[]>(simulationSets || [])
  const [dupProjList, setDupProjList] = useState<Project[]>([])
  const [dupeProject, setDupeProject] = useState<Project>()
  const [refRuns, setRefRuns] = useState<WorkflowSubmission[]>(latestSubmissions)
  const [geoRuns, setGeoRuns] = useState<WorkflowSubmission[]>(latestSubmissions)
  const [{ data: refRunData, loading: laodingRefRun }, fetchRefRuns] = useGetSubmissions()
  const [{ data: geoRunData, loading: laodingGeoRun }, fetchGeoRuns] = useGetSubmissions()
  const [{ data: projsData, loading: laodingProjs }, fetchProjs] = useGetProjects()

  const labelRequiredByF1Team = classNames('mb-2', { 'text-gray-400': !isF1Team })

  useEffect(() => {
    if (mode === 'new') {
      setValue('stDate', new Date())
      const me = engineers.find((item) => item.cloudProviderLDAPRef === currentUser?.cloudProviderLDAPRef)
      me && setValue('engineer', me)

      const sort = {
        timeCreated: -1
      }
      // @ts-ignore
      setValue('status', ProjectStatus.ACTIVE)
      fetchProjs(`limit=10&sort=${JSON.stringify(sort)}`)
    } else if (mode === 'edit') {
      // @ts-ignore
      setValue('status', initProject.status)
      setValue('engineer', initProject.engineer)
      // @ts-ignore
      setValue('isBaseline', initProject.isBaseline)
      setValue('area', initProject.area)

      setBaselineRequired(!initProject.isBaseline)

      if (initProject.methodSet) {
        const tmpMethod = methodologySetList.find((item) => item._id === initProject.methodSet._id)
        if (tmpMethod) {
          setValue('methodSet', tmpMethod)
        } else {
          setMethodologySetList([...methodologySetList, initProject.methodSet])
          setValue('methodSet', initProject.methodSet)
        }
      }

      setValue('referenceRun', initProject.referenceRun)
      if (initProject.referenceRunName) {
        if (refRuns.find((item) => item.name === initProject.referenceRunName)) {
          setValue('referenceRunName', initProject.referenceRunName)
        } else {
          const tmpRun = {
            _id: initProject.referenceRunName,
            name: initProject.referenceRunName
          } as WorkflowSubmission
          setRefRuns([...refRuns, tmpRun])
          setValue('referenceRunName', tmpRun.name)
        }
      }

      if (initProject.baselineRunName) {
        if (geoRuns.find((item) => item.name === initProject.baselineRunName)) {
          setValue("baselineRunName", initProject.baselineRunName)
        } else {
          const tmpRun = {
            _id: initProject.baselineRunName,
            name: initProject.baselineRunName
          } as WorkflowSubmission
          setGeoRuns([...geoRuns, tmpRun])
          setValue('baselineRunName', tmpRun.name)
        }
      }

      if (initProject.attitudeSet) {
        const tmpAttitudeSet = attitudeSets.find((item) => item._id === initProject.attitudeSet._id)
        if (!tmpAttitudeSet) {
          setAttitudeSetList([...attitudeSetList, initProject.attitudeSet])
          setValue('attitudeSet', initProject.attitudeSet)
        } else {
          setValue('attitudeSet', tmpAttitudeSet)
        }
      }

      if (isF1Team && initProject.domainSet) {
        const tmpDomainSet = domainSets.find((item) => item._id === initProject.domainSet._id)
        if (!tmpDomainSet) {
          setDomainSetList([...domainSetList, initProject.domainSet])
          setValue('domainSet', initProject.domainSet)
        } else {
          setValue('domainSet', tmpDomainSet)
        }
      }

      if (isF1Team && initProject.tyreSet) {
        const tmpTyreSet = tyreSets.find((item) => item._id === initProject.tyreSet._id)
        if (!tmpTyreSet) {
          setTyreSetSetList([...tyreSetList, initProject.tyreSet])
          setValue('tyreSet', initProject.tyreSet)
        } else {
          setValue('tyreSet', tmpTyreSet)
        }
      }

      if (isF1Team && initProject.simulationSet) {
        const tmpSimulationSet = simulationSets.find((item) => item._id === initProject.simulationSet._id)
        if (!tmpSimulationSet) {
          setSimulationSetList([...simulationSetList, initProject.simulationSet])
          setValue('simulationSet', initProject.simulationSet)
        } else {
          setValue('simulationSet', tmpSimulationSet)
        }
      }

      if (initProject.baselineSubmissionTemplate) {
        if (!templates.find((item) => item._id === initProject.baselineSubmissionTemplate)) {
          setTemplateList([...templates, {
            _id: initProject.baselineSubmissionTemplate,
            name: initProject.baselineSubmissionTemplate
          } as WorkflowSubmissionTemplate
          ])
        }
        setValue('baselineSubmissionTemplate', initProject.baselineSubmissionTemplate)
      }
      isF1Team && initProject.projectConfig && setValue('projectConfig', initProject.projectConfig)
      setValue('baselineRunName', initProject.baselineRunName)
      setValue('description', initProject.description)
    }
  }, [mode, initProject])

  useEffect(() => {
    if (refRunData === undefined) {
      return
    }
    if (refRunData && refRunData.protoBytesList.length) {
      const submissions = protoService.decodeProtoResponse(refRunData, 'workflowsubmission')
      if (mode === 'edit' && initProject.referenceRun) {
        const tmpRun = submissions.find((item) => item._id === initProject.referenceRun)
        if (tmpRun) {
          setValue('referenceRunName', tmpRun.name)
        }
      }
      setRefRuns(
        (submissions as WorkflowSubmission[]).map((item) => {
          return {
            _id: item._id,
            name: item.name
          } as WorkflowSubmission
        })
      )
    } else {
      /**
       * this is supposed to set a empty array, because a bug of filter resetting, we have to use this for now
       */
      setRefRuns([{
        _id: '',
        name: 'Use Search to select run'
      } as WorkflowSubmission
      ])
    }
  }, [refRunData])

  useEffect(() => {
    if (geoRunData === undefined) {
      return
    }
    if (geoRunData && geoRunData.protoBytesList.length) {
      const submissions = protoService.decodeProtoResponse(geoRunData, 'workflowsubmission')
      if (mode === 'edit' && initProject.baselineRunName) {
        const tmpRun = submissions.find((item) => item._id === initProject.baselineRunName)
        if (tmpRun) {
          setValue('baselineRunName', tmpRun.name)
        }
      }
      setGeoRuns(
        (submissions as WorkflowSubmission[]).map((item) => {
          return {
            _id: item._id,
            name: item.name
          } as WorkflowSubmission
        })
      )
    } else {
      /**
       * this is supposed to set a empty array, because a bug of filter resetting, we have to use this for now
       */
      setRefRuns([{
        _id: '',
        name: 'Use Search to select run'
      } as WorkflowSubmission
      ])
    }
  }, [geoRunData])

  useEffect(() => {
    if (projsData && projsData.protoBytesList.length) {
      const projs = protoService.decodeProtoResponse(projsData, 'project')
      setDupProjList(projs as Project[])
    } else {
      /**
       * this is supposed to set a empty array, because a bug of filter resetting, we have to use this for now
       */
      setDupProjList([{
        _id: '',
        name: 'No projects found'
      } as Project
      ])
    }
  }, [projsData])

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name].message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const onSubmit: SubmitHandler<Project> = (formData: Project) => {
    if (formData.referenceRunName) {
      const tmpRun = refRuns.find((item) => item.name === formData.referenceRunName)
      if (tmpRun) {
        formData.referenceRun = tmpRun._id
      }
    }
    if (formData.baselineRunName) {
      const tmpRun = geoRuns.find((item) => item.name === formData.baselineRunName)
      if (tmpRun) {
        formData.baselineRunId = tmpRun._id
      }
    }
    console.log('formData', formData)
    onSave && onSave(formData)
  }

  const handleDuplicate = () => {
    if (!dupeProject) {
      return
    }

    reset()
    // @ts-ignore
    setValue('status', ProjectStatus.ACTIVE)
    setValue('area', dupeProject.area)
    setValue('engineer', dupeProject.engineer)
    setValue('description', dupeProject.description)
    if (isF1Team && dupeProject.projectConfig) {
      setValue('projectConfig', dupeProject.projectConfig)
    }
    if (dupeProject.methodSet) {
      let methodologySet = methodologySets.find((item) => item._id === dupeProject.methodSet._id)
      if (methodologySet) {
        setValue('methodSet', methodologySet)
      } else {
        setMethodologySetList([...methodologySetList, dupeProject.methodSet])
        setValue('methodSet', dupeProject.methodSet)
      }
    }
    if (dupeProject.attitudeSet) {
      let attitudeSet = attitudeSets.find((item) => item._id === dupeProject.attitudeSet._id)
      if (attitudeSet) {
        setValue('attitudeSet', attitudeSet)
      } else {
        setAttitudeSetList([...attitudeSetList, dupeProject.attitudeSet])
        setValue('attitudeSet', dupeProject.attitudeSet)
      }
    }
    if (isF1Team && dupeProject.domainSet) {
      let domainSet = domainSets.find((item) => item._id === dupeProject.domainSet._id)
      if (domainSet) {
        setValue('domainSet', domainSet)
      } else {
        setDomainSetList([...domainSetList, dupeProject.domainSet])
        setValue('domainSet', dupeProject.domainSet)
      }
    }
    if (isF1Team && dupeProject.tyreSet) {
      let tyreSet = tyreSets.find((item) => item._id === dupeProject.tyreSet._id)
      if (tyreSet) {
        setValue('tyreSet', tyreSet)
      } else {
        setTyreSetSetList([...tyreSetList, dupeProject.tyreSet])
        setValue('tyreSet', dupeProject.tyreSet)
      }
    }
    if (isF1Team && dupeProject.simulationSet) {
      let simulationSet = simulationSets.find((item) => item._id === dupeProject.simulationSet._id)
      if (simulationSet) {
        setValue('simulationSet', simulationSet)
      } else {
        setSimulationSetList([...simulationSetList, dupeProject.simulationSet])
        setValue('simulationSet', dupeProject.simulationSet)
      }
    }
    setValue('baselineSubmissionTemplate', dupeProject.baselineSubmissionTemplate)
    if (dupeProject.projectConfig) {
      setValue('projectConfig', dupeProject.projectConfig)
    }

    setValue("referenceRun", dupeProject.referenceRun)
    if (dupeProject.referenceRunName) {
      if (refRuns.find((item) => item.name === dupeProject.referenceRunName)) {
        setValue('referenceRunName', dupeProject.referenceRunName)
      } else {
        const tmpRun = {
          _id: dupeProject.referenceRunName,
          name: dupeProject.referenceRunName
        } as WorkflowSubmission
        setRefRuns([...refRuns, tmpRun])
        setValue('referenceRunName', tmpRun.name)
      }
    }

    if (dupeProject.baselineRunName) {
      if (geoRuns.find((item) => item.name === dupeProject.baselineRunName)) {
        setValue("baselineRunName", dupeProject.baselineRunName)
      } else {
        const tmpRun = {
          _id: dupeProject.baselineRunName,
          name: dupeProject.baselineRunName
        } as WorkflowSubmission
        setGeoRuns([...geoRuns, tmpRun])
        setValue('baselineRunName', tmpRun.name)
      }
    }

  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col items-stretch relative">
        {mode === 'new' && <div className="flex mt-4">
          <div className="flex items-center space-x-2 p-2 rounded-md bg-light-blue dark:bg-neutral-80 min-w-fit">
            <div className="dark:text-neutral">Duplicate Project</div>
            <Dropdown
              disabled={saving}
              value={dupeProject}
              loading={laodingProjs}
              pt={{
                ...DropdownStyle,
                root: {
                  ...DropdownStyle.root,
                  className: 'min-w-[250px] max-w-[250px] dark:bg-transparent dark:border-neutral-70'
                },
              }}
              filter
              filterBy='name'
              dataKey='_id'
              showClear
              onFilter={(e) => {
                if (e.filter.length < 2) {
                  return
                }
                const nameSearch = { $regex: e.filter, $options: 'i' }
                const query = queryString2JsonString(
                  'name=' + encodeURIComponent(JSON.stringify(nameSearch))
                )
                fetchProjs(`query=${query}&limit=20`)
              }}
              onChange={(e) => {
                if (e.value) {
                  e.value._id && setDupeProject(e.value)
                } else {
                  setDupeProject(e.value)
                }
              }}
              itemTemplate={(item) => {
                return item._id ? item.name : null
              }}
              options={dupProjList}
              optionLabel="name"
              optionValue="_id"
              useOptionAsValue
              placeholder="Select Project"
              emptyMessage="&nbsp;&nbsp;No projects"
              emptyFilterMessage="&nbsp;&nbsp;No projects found"
            />
            <Button
              onClick={handleDuplicate}
              type="button"
              disabled={!dupeProject || saving}
            >
              <CopyIcon className="w-4 h-4 fill-white" />
              <span>Duplicate</span>
            </Button>
          </div>
        </div>}

        <div className="absolute -right-1 -top-1 z-10">
          <SaveButton
            title="Save Project"
            dataPrAt="center bottom"
            loading={saving}
          />
        </div>
        <div className="grid grid-cols-4 gap-x-12 gap-y-4 mt-8">
          <div className="flex flex-col items-stretch">
            <Controller
              name="status"
              control={control}
              rules={{ required: 'Status is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Status
                  </label>
                  <Dropdown
                    value={field.value}
                    pt={{
                      root: {
                        className: 'min-w-full bg-transparent dark:border-neutral-70 dark:bg-transparent'
                      },
                      input: {
                        className: 'py-2'
                      }
                    }}
                    onChange={(e) => {
                      field.onChange(e.value)
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={ProjectStatusList}
                    placeholder="Select Status"
                    optionLabel="name"
                    optionValue="value"
                    emptyMessage="&nbsp;&nbsp;No data"
                  />
                </>
              )}
            />
          </div>

          <div className="flex flex-col items-stretch">
            <Controller
              name="engineer"
              control={control}
              rules={{ required: 'Engineer is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Responsible Engineer
                  </label>
                  <Dropdown
                    disabled={initProject.status === ProjectStatus.ARCHIVED}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    filterBy="firstName,lastName"
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ error: fieldState.error })}
                    options={engineers || []}
                    optionValue="_id"
                    optionLabel="firstName"
                    useOptionAsValue
                    itemTemplate={(item) => {
                      return `${item.firstName} ${item.lastName}`
                    }}
                    valueTemplate={(item) => {
                      return item ? `${item.firstName} ${item.lastName}` : 'Select Engineer'
                    }}
                    placeholder="Select Engineer"
                    emptyMessage="&nbsp;&nbsp;No data"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div
            className={classNames('flex flex-col items-stretch', {
              'col-span-2': true
            })}>
            <Controller
              name="isBaseline"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="opacity-0">
                    Baseline
                  </label>
                  <div className="flex-grow flex items-center">
                    <Checkbox
                      inputId={field.name}
                      pt={CheckboxStyle}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.checked)
                        setBaselineRequired(!e.checked)
                        if (e.checked) {
                          setValue('baselineRunName', null)
                          setValue('baselineRunId', null)
                        }
                      }}></Checkbox>
                    <label htmlFor={field.name} className="ml-2 font-normal">
                      Set Project as Baseline
                    </label>
                  </div>
                </>
              )}
            />
          </div>

          <div className="flex flex-col items-stretch">
            <Controller
              name="area"
              control={control}
              rules={{ required: 'Area is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Area
                  </label>
                  <Dropdown
                    disabled={initProject.status === ProjectStatus.ARCHIVED}
                    value={field.value}
                    pt={DropdownStyle}
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ error: fieldState.error })}
                    options={Areas}
                    readOnly={mode === 'view'}
                    placeholder="Select Area"
                    emptyMessage="&nbsp;&nbsp;No data"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="methodSet"
              control={control}
              rules={{ required: 'Methodology set is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Methodology Set
                  </label>
                  <Dropdown
                    disabled={initProject.status === ProjectStatus.ARCHIVED}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ error: fieldState.error })}
                    options={methodologySetList}
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    readOnly={mode === 'view'}
                    placeholder="Select Methodology Set"
                    emptyMessage="&nbsp;&nbsp;No Methodology Set"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="baselineSubmissionTemplate"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Submission Template
                  </label>
                  <Dropdown
                    disabled={initProject.status === ProjectStatus.ARCHIVED}
                    value={field.value}
                    filter
                    pt={DropdownStyle}
                    onChange={(e) => {
                      field.onChange(e.value)
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={templateList}
                    optionLabel="name"
                    optionValue="_id"
                    readOnly={mode === 'view'}
                    placeholder="Select Baseline Template"
                    emptyMessage="&nbsp;&nbsp;No data"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="projectConfig"
              control={control}
              defaultValue={initProject.projectConfig}
              rules={{ required: isF1Team ? 'Project configutation is required.' : false }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className={labelRequiredByF1Team}>
                    Project Configuration
                  </label>
                  <Dropdown
                    disabled={initProject.status === ProjectStatus.ARCHIVED || !isF1Team}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    filterBy="name"
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ error: fieldState.error })}
                    options={projectConfigList || []}
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    valueTemplate={(item) => {
                      if (item) {
                        return item.name
                      } else {
                        return 'Select Config'
                      }
                    }}
                    itemTemplate={(item) => {
                      return item.name
                    }}
                    placeholder="Select Config"
                    readOnly={mode === 'view'}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="attitudeSet"
              control={control}
              rules={{ required: 'Attitude set sequence run is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Attitude Set
                  </label>
                  <Dropdown
                    disabled={initProject.status === ProjectStatus.ARCHIVED}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    onChange={(e) => {
                      console.log(e.value)
                      field.onChange(e.value)
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={attitudeSetList}
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    readOnly={mode === 'view'}
                    placeholder="Select Attitude Set"
                    emptyMessage="&nbsp;&nbsp;No Attitude Set"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="field-box">
            <Controller
              name="domainSet"
              control={control}
              rules={{ required: isF1Team ? 'Domain set is required.' : false }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className={labelRequiredByF1Team}>
                    Domain Set
                  </label>
                  <Dropdown
                    disabled={initProject.status === ProjectStatus.ARCHIVED || !isF1Team}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ error: fieldState.error })}
                    options={domainSetList}
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    readOnly={mode === 'view'}
                    placeholder="Select Domain Set"
                    emptyMessage="&nbsp;&nbsp;No Domain Set"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="field-box">
            <Controller
              name="tyreSet"
              control={control}
              rules={{ required: isF1Team ? 'Tyre set is required.' : false }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className={labelRequiredByF1Team}>
                    Tyre Set
                  </label>
                  <Dropdown
                    disabled={initProject.status === ProjectStatus.ARCHIVED || !isF1Team}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ error: fieldState.error })}
                    options={tyreSetList}
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    readOnly={mode === 'view'}
                    placeholder="Select Tyre Set"
                    emptyMessage="&nbsp;&nbsp;No Tyre Set"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="field-box">
            <Controller
              name="simulationSet"
              control={control}
              rules={{ required: isF1Team ? 'Simulation set is required.' : false }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className={labelRequiredByF1Team}>
                    Simulation Set
                  </label>
                  <Dropdown
                    disabled={initProject.status === ProjectStatus.ARCHIVED || !isF1Team}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ error: fieldState.error })}
                    options={simulationSetList}
                    optionLabel="name"
                    optionValue="_id"
                    useOptionAsValue
                    readOnly={mode === 'view'}
                    placeholder="Select Simulation Set"
                    emptyMessage="&nbsp;&nbsp;No Simulation Set"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="baselineRunName"
              control={control}
              rules={{ required: baselineRequired ? 'Master geometry is required.' : false }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Master Geometry
                  </label>
                  <Dropdown
                    disabled={initProject.status === ProjectStatus.ARCHIVED}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    loading={laodingGeoRun}
                    filterBy="name"
                    showClear
                    valueTemplate={(item) => {
                      return item ? item.name : 'None'
                    }}
                    onFilter={(e) => {
                      if (e.filter.length < 1) {
                        return
                      }
                      const nameSearch = { $regex: e.filter, $options: 'i' }
                      const query = queryString2JsonString(
                        'name=' + encodeURIComponent(JSON.stringify(nameSearch))
                      )
                      fetchGeoRuns(`query=${query}&limit=10`)
                    }}
                    onChange={(e) => {
                      if (e.value === 'Use Search to select run') {
                        return
                      }
                      field.onChange(e.value)
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={geoRuns}
                    optionLabel="name"
                    optionValue="name"
                    readOnly={mode === 'view'}
                    placeholder="Select Master Geometry"
                    emptyMessage="&nbsp;&nbsp;Use Search to select run"
                    emptyFilterMessage="&nbsp;&nbsp;No runs found"
                    filterPlaceholder="Enter run ID to search"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box">
            <Controller
              name="referenceRunName"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Reference Run
                  </label>
                  <Dropdown
                    disabled={initProject.status === ProjectStatus.ARCHIVED}
                    value={field.value}
                    pt={DropdownStyle}
                    filter
                    loading={laodingRefRun}
                    filterBy="name"
                    showClear
                    onFilter={(e) => {
                      if (e.filter.length < 1) {
                        return
                      }
                      const nameSearch = { $regex: e.filter, $options: 'i' }
                      const query = queryString2JsonString(
                        'name=' + encodeURIComponent(JSON.stringify(nameSearch))
                      )
                      fetchRefRuns(`query=${query}&limit=10`)
                    }}
                    onChange={(e) => {
                      if (e.value === 'Use Search to select run') {
                        return
                      }
                      field.onChange(e.value)
                    }}
                    className={classNames({ error: fieldState.error })}
                    options={refRuns}
                    optionLabel="name"
                    optionValue="name"
                    readOnly={mode === 'view'}
                    placeholder="None"
                    emptyMessage="&nbsp;&nbsp;Use Search to select run"
                    emptyFilterMessage="&nbsp;&nbsp;No runs found"
                    filterPlaceholder="Enter run ID to search"
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="flex flex-col items-stretch col-span-2">
            <Controller
              name="description"
              control={control}
              disabled={initProject.status === ProjectStatus.ARCHIVED}
              defaultValue={initProject.description}
              rules={{ required: 'Description is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Description
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default ProjectForm
