import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { DataTable, DataTableExpandedRows, DataTableFilterMeta } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useRecoilValue } from 'recoil'
import { useGetSimulationSet, useGetSimulationSets, useUpdateSimulationSet, useUpdateSimulationState } from '../api/simulation-set-API'
import { CopyIcon, EditIcon, PlusIcon } from '../components/Icons'
import { protoService } from '../proto/ProtoService'
import { LazyTableState, SimulationSet } from '../types/types'
import { queryString2JsonString } from '../types/util'
import { DefaultPageSize } from '../utils/constants'
import { currentTeamState } from '../utils/state-atoms'
import SaveButton from '../components/buttons/SaveButton'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { DropdownStyle, TabMenuStyle } from '../utils/styling-constants'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Column, ColumnBodyOptions } from 'primereact/column'
import { SimulationState, SimulationStateType } from '@aero-platform/shared'
import IconButton from '../components/buttons/IconButton'
import SimulationSetStateEditor from '../components/SimulationSetStateEditor'
import { TabMenu } from 'primereact/tabmenu'

const defaultFilters: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  methodId: { value: null, matchMode: FilterMatchMode.EQUALS },
  status: { value: null, matchMode: FilterMatchMode.EQUALS }
}

const tabItems = [
  { label: 'Config' },
  { label: 'Logs' }
];

const SimulationSets: FC = () => {
  const currentTeam = useRecoilValue(currentTeamState)
  const [mode, setMode] = useState('')
  const [editorMode, setEditorMode] = useState('')
  const [stateIndex, setStateIndex] = useState(0)
  const [showStateEditor, setShowStateEditor] = useState(false)
  const [{ loading: loadingSimulations, data: simulationSetsData }, fetchSimulationSets] = useGetSimulationSets()
  const [{ loading: loadingSimulation, data: oneSimulationSetData }, fetchOneSimulationSet] = useGetSimulationSet()
  const [{ loading: updating }, updateSimulationSet] = useUpdateSimulationSet()
  const [{ loading: updatingState }, updateSimulationSetState] = useUpdateSimulationState()

  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({})

  const [simulationSets, setSimulationSets] = useState<SimulationSet[]>([])
  const [currentSimulationSet, setCurrentSimulationSet] = useState({} as SimulationSet)
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [simulationStates, setSimulationStates] = useState<SimulationState[]>([] as SimulationState[])
  const [lazyState, setlazyState] = useState<LazyTableState>({
    first: 0,
    rows: DefaultPageSize,
    page: 1,
    sortField: 'name',
    sortOrder: -1,
    filters: defaultFilters,
    sumlvl: 'vehicleStatesList:0,geometryList:0,simulationSetList:0,cfdSequenceList:0,method:0'
  })

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<SimulationSet>()

  useEffect(() => {
    if (simulationSetsData) {
      const tmpList = simulationSetsData.protoBytesList.map((item: string) => {
        return protoService.deSerialize(item, 'simulationset')
      })
      tmpList && setSimulationSets(tmpList)
    } else {
      setSimulationSets([])
    }
  }, [simulationSetsData])

  useEffect(() => {
    if (oneSimulationSetData) {
      console.log(oneSimulationSetData)
      if (oneSimulationSetData.protoBytesList && oneSimulationSetData.protoBytesList.length) {
        const simulationSet = protoService.deSerialize(oneSimulationSetData.protoBytesList[0], 'simulationset')
        setCurrentSimulationSet(simulationSet)
        setSimulationStates(simulationSet.simulationStates.map((item) => {
          return { ...item } as SimulationState
        }))
      } else {
        setSimulationSets([])
        setSimulationStates([])
        toast.error('Error loading simulation set.')
      }
    }
  }, [oneSimulationSetData])

  const makeQuery = (state) => {
    let query
    console.log(state)

    if (state.filters.name['value']) {
      const nameSearch = { $regex: state.filters.name['value'], $options: 'i' }
      const q = 'name=' + encodeURIComponent(JSON.stringify(nameSearch))
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.methodId['value']) {
      const q = 'methodId=' + state.filters.methodId['value']
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.status['value']) {
      const q = 'status=' + state.filters.status['value']
      query = query ? `${query}&${q}` : q
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${state.rows}` : `limit=${state.rows}`
    query = `${query}&skip=${state.first}&countTotal=true`
    if (state.sortField) {
      const sort = {
        [state.sortField]: state.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }

    return query
  }

  useEffect(() => {
    const query = makeQuery(lazyState)
    fetchSimulationSets(query).catch((err) => {
      console.error(err)
      toast.error('Error loading simulationSets.')
    })
  }, [lazyState])

  const handleCreateNewSimulationSet = () => {
    if (mode === 'new') {
      return
    }
    reset()
    setMode('new')
    setValue('name', "")
    setValue('description', "")
    setCurrentSimulationSet({} as SimulationSet)
    setSimulationStates([])
  }

  const handleCopySimulationSet = (e) => {
    if (mode === 'new') {
      toast.error('Cannot duplicate while creating a new simulation set.')
      return
    }
    if (!currentSimulationSet._id) {
      toast.error('Please select a simulation set to duplicate.')
      return
    }

    setMode('new')
    setValue('name', currentSimulationSet.name + ' (Copy)')
    setValue('description', currentSimulationSet.description)
    setSimulationStates(simulationStates.map((item) => {
      return { ...item } as SimulationState
    }))
  }

  const handleItemSelected = (simulationSet) => {
    if (!simulationSet) {
      return
    }
    fetchOneSimulationSet(simulationSet._id).catch((err) => {
      toast.error('Error loading simulation set.')
    })

    setCurrentSimulationSet(simulationSet)
    setMode('edit')
    reset()
    setValue('name', simulationSet.name)
    setValue('description', simulationSet.description)
    if (simulationSet.simulationStates) {
      setSimulationStates(simulationSet.simulationStates.map((item) => {
        return { ...item } as SimulationState
      }))
    } else {
      setSimulationStates([])
    }
  }

  const onSubmit: SubmitHandler<SimulationSet> = async (formData: SimulationSet) => {
    if (!currentSimulationSet._id) {
      return
    }
    const newSimulationSet = new SimulationSet(formData)
    newSimulationSet._id = currentSimulationSet._id
    newSimulationSet.raceTeamId = currentTeam
    const dataToPost = protoService.encodePayload(newSimulationSet, 'simulationset')
    try {
      await updateSimulationSet({
        id: currentSimulationSet._id,
        data: dataToPost
      } as any)
      setSimulationSets(
        simulationSets.map((item) => {
          if (item._id === currentSimulationSet._id) {
            return newSimulationSet
          } else {
            return item
          }
        })
      )
      setCurrentSimulationSet(newSimulationSet)
      toast.success('Simulation set updated.')
    } catch (err) {
      console.error(err)
      toast.error('Error updating simulation set.')
    }
  }

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name]?.message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const stateColumns = [
    {
      header: 'Name',
      field: 'name',
      filter: true,
      headerStyle: {},
      bodyStyle: {},
      bodyClassName: 'id-cell max-w-[200px] w-[200px]',
      headerClassName: 'max-w-[200px]',
      sortable: true,
    },
    {
      header: 'Description',
      field: 'description',
    },
    {
      header: 'Type',
      field: 'type',
      bodyClassName: 'id-cell max-w-[100px] w-[100px]',
      headerClassName: 'max-w-[100px]',
      body: (rowData: SimulationState) => {
        return SimulationStateType[rowData.type]
      }
    },
  ]

  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full space-y-4">
      <div className="flex items-center justify-between pb-4">
        <h1 className="page-title">Simulation Sets</h1>
        <Button
          type="button"
          onClick={handleCreateNewSimulationSet}
          severity="secondary"
          className="icon-button !hidden"
          disabled={mode === 'new'}
          tooltip='Create New Simulation Set'
          tooltipOptions={{ position: 'bottom' }}
          data-pr-at='center+45 bottom'
        >
          <PlusIcon className="fill-white" />
        </Button>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full grid grid-cols-2 gap-x-2 gap-y-4 mt-8">
        <div className="flex items-center col-span-2 space-x-2">
          <Dropdown
            filter
            loading={loadingSimulations}
            filterBy="name"
            filterMatchMode="contains"
            showFilterClear
            onFilter={(e) => {
              setlazyState({
                ...lazyState,
                filters: {
                  ...lazyState.filters,
                  name: { value: e.filter, matchMode: FilterMatchMode.CONTAINS }
                }
              })
            }}
            value={currentSimulationSet}
            emptyMessage="No SimulationSets Found"
            pt={{
              ...DropdownStyle,
              root: {
                ...DropdownStyle.root,
                className: 'min-w-[30%] max-w-[50%]'
              },
            }}
            optionValue="_id"
            optionLabel="name"
            useOptionAsValue
            options={simulationSets}
            valueTemplate={(option) => {
              let value
              if (mode === 'new') {
                value = 'New SimulationSet'
              } else if (option) {
                value = option.name
              } else {
                value = (currentSimulationSet && currentSimulationSet.name) || 'Select Simulation Set'
              }
              return <div>{value}</div>
            }}
            itemTemplate={(option) => <div>{option && option._id ? option.name : ''}</div>}
            onChange={(e) => handleItemSelected(e.value)}
            placeholder="Select Simulation Set"
          />
          <Button
            type="button"
            onClick={handleCopySimulationSet}
            className="icon-button !hidden"
            tooltip='Duplicate Simulation Set'
            tooltipOptions={{ position: "bottom" }}
            data-pr-at='center+5 bottom'>
            <CopyIcon className="fill-white w-4 h-4" />
          </Button>
          <SaveButton loading={updating} title="Save Simulation Set" />
        </div>
        <div className="col-span-2">
          <TabMenu model={tabItems}
            onChange={(e) => console.log(e)}
            activeIndex={currentTabIndex}
            pt={TabMenuStyle}
            onTabChange={(e) => setCurrentTabIndex(e.index)} />
          <div className='min-h-4'> </div>
          {currentSimulationSet && currentTabIndex === 0 &&
            <>
              <section className="grid grid-cols-2 space-x-2">
                <div className="field-box">
                  <Controller
                    name="name"
                    control={control}
                    disabled={updating || !currentSimulationSet._id}
                    rules={{ required: 'Name is required.' }}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className="mb-2">
                          Name
                        </label>
                        <InputText
                          id={field.name}
                          {...field}
                          className={classNames({ error: fieldState.error })}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>

                <div className="field-box">
                  <Controller
                    name="description"
                    control={control}
                    disabled={updating || !currentSimulationSet._id}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className="mb-2">
                          Description
                        </label>
                        <InputText
                          id={field.name}
                          {...field}
                          className={classNames({ error: fieldState.error })}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              </section>
              <div className='font-semibold'>States</div>
              <section className="col-span-2">
                <DataTable
                  value={simulationStates}
                  loading={loadingSimulation}
                  dataKey="name"
                  filterDisplay="row"
                  tableClassName="simulation-set-states"
                  stripedRows
                  sortField='vehicleId'
                  pt={{
                    bodyRow: {
                      className: 'table-row'
                    },
                    rowExpansion: {
                      className: 'pt-0'
                    }
                  }}>
                  {stateColumns.map((item) => {
                    return (
                      <Column
                        key={item.field}
                        field={item.field}
                        header={item.header}
                        showFilterMenu={false}
                        sortable={item.sortable}
                        headerStyle={item.headerStyle}
                        headerClassName={item.headerClassName}
                        body={item.body}
                        bodyStyle={item.bodyStyle}
                        bodyClassName={item.bodyClassName}></Column>
                    )
                  })}
                  <Column
                    body={(rowData: SimulationState, options: ColumnBodyOptions) => {
                      return (
                        <div className="flex items-center space-x-1 justify-end">
                          <IconButton
                            severity="secondary"
                            icon={<EditIcon className="h-4 w-4 fill-white stroke-transparent" />}
                            onClick={() => {
                              setStateIndex(options.rowIndex)
                              setEditorMode('edit')
                              setShowStateEditor(true)
                            }}
                          >
                          </IconButton>
                        </div>
                      )
                    }}
                    headerClassName="one-col-editor-header editor-header !w-12 max-w-12"
                    bodyClassName="!pr-0 !max-w-12"
                    pt={{
                      headerContent: {
                        className: 'pr-0 min-w-full'
                      },
                      headerTitle: {
                        className: 'pr-0 w-full flex items-center'
                      },
                    }}
                    bodyStyle={{ textAlign: 'center', padding: '12px 8px' }}></Column>
                </DataTable>
              </section>
            </>
          }
        </div>
      </form >

      {showStateEditor &&
        <SimulationSetStateEditor
          mode={editorMode}
          stateIndex={stateIndex}
          states={simulationStates}
          saving={updatingState}
          onConfirm={async (stateData, stateMode) => {
            let newState = new SimulationState()
            newState.name = simulationStates[stateIndex].name
            newState.description = stateData.description
            try {
              await updateSimulationSetState({
                id: currentSimulationSet._id,
                data: protoService.encodePayload(newState, 'simulationstate')
              } as any)
              setShowStateEditor(false)
              fetchOneSimulationSet(currentSimulationSet._id).catch((err) => {
                toast.error('Error loading simulation set.')
              })
              toast.success('Simulation state updated.')
            } catch (err) {
              console.error(err)
              toast.error('Error updating simulation state.')
            }
          }}
          onCancel={() => {
            setShowStateEditor(false)
          }}
        />}

    </div >
  )
}

export default SimulationSets
