import { DeploySet, DeployState, PipelineRelease, SelectedSystem } from "@aero-platform/shared"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { FC, useEffect, useState } from "react"
import { Control, Controller, UseFormSetValue } from "react-hook-form"
import { SelectOption } from "../types/types"

type MethodSetSectionProps = {
  sectionKey: string,
  sectionIndex: number,
  sectionName: string
  deploySets: DeploySet[]
  selectedSystem: SelectedSystem
  control: Control<any>
  setValue: UseFormSetValue<any>
  disabled?: boolean
  mode: string
}
const MethodSetSection: FC<MethodSetSectionProps> = ({
  sectionKey, sectionIndex, deploySets, sectionName, selectedSystem, control, setValue, disabled = false, mode
}) => {
  if (sectionIndex === undefined || sectionIndex === -1) {
    // return null
  }
  const [vendorVersions, setVendorVersions] = useState<SelectOption[]>([])
  const [branchNames, setBranchNames] = useState<SelectOption[]>([])
  const [commits, setCommits] = useState<DeploySet[]>([])
  const [scripts, setScripts] = useState<DeployState[]>([])
  const [selectedVersion, setSelectedVersion] = useState<string>(selectedSystem?.vendorVersion)
  const [selectedBranch, setSelectedBranch] = useState<string>(selectedSystem?.branchName)
  const [selectedCommit, setSelectedCommit] = useState<string>(selectedSystem?.commitId)

  const getUniqueBranches = (version: string) => {
    const branches = new Set(deploySets
      .filter((item) => item.vendorCompatibilityVersion === version)
      .map((item) => {
        return item.branchName
      }))

    return Array.from(branches).map((item) => ({ label: item, value: item }))
  }

  const getCommits = (version: string, branch: string) => {
    const commits = deploySets.filter((item) => {
      return item.vendorCompatibilityVersion === version && item.branchName === branch
    }).map((item) => {
      return {
        _id: item._id,
        commitId: item.commitId,
        deployStates: item.deployStates
      } as DeploySet
    })

    return commits
  }

  useEffect(() => {
    if (deploySets.length > 0) {
      const uniqueVersions = []
      deploySets.forEach((item) => {
        if (uniqueVersions.includes(item.vendorCompatibilityVersion)) {
          return
        }
        uniqueVersions.push(item.vendorCompatibilityVersion)
      })
      setVendorVersions(uniqueVersions.map((item) => ({ label: item, value: item })))
    }
  }, [])

  useEffect(() => {
    if (mode === 'add') {
      return
    }

    // for edit mode
    if (selectedSystem?.vendorVersion) {
      // filter branches based on selected vendor version
      setBranchNames(getUniqueBranches(selectedSystem.vendorVersion))

      if (selectedSystem?.branchName) {
        const commits = getCommits(selectedSystem.vendorVersion, selectedSystem.branchName)
        setCommits(commits || [])

        if (selectedSystem?.commitId) {
          const deploySet = commits.find((item) => item.commitId === selectedSystem.commitId)
          deploySet && setScripts(deploySet.deployStates)
        } else {
          setScripts([])
        }
      } else {
        setCommits([])
        setScripts([])
      }
    } else {
      setBranchNames([])
      setCommits([])
      setScripts([])
    }

  }, [selectedSystem, sectionIndex, mode])

  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4">
      <div className="field-box">
        <Controller
          name={`selectedSystems.${sectionIndex}.vendorVersion`}
          control={control}
          rules={{ required: 'Version is required.' }}
          render={({ field, fieldState }) => (
            <>
              <label htmlFor={field.name} className="mb-2">
                {`${sectionName} Version`}
              </label>
              <Dropdown
                value={field.value}
                disabled={disabled}
                pt={{
                  root: {
                    className: 'min-w-full bg-transparent dark:border-neutral-70 dark:bg-transparent'
                  },
                  input: {
                    className: 'py-2'
                  }
                }}
                onChange={(e) => {
                  field.onChange(e.value)
                  setSelectedVersion(e.value)
                  setBranchNames(getUniqueBranches(e.value))
                  setValue(`selectedSystems.${sectionIndex}.branchName`, null)
                  setValue(`selectedSystems.${sectionIndex}.commitId`, null)
                  setValue(`selectedSystems.${sectionIndex}.entryScriptId`, null)
                }}
                className={classNames({ error: fieldState.error })}
                options={vendorVersions}
                placeholder="Select Version"
                optionLabel="label"
                optionValue="value"
                emptyMessage="&nbsp;&nbsp;No data"
              />
            </>
          )}
        />
      </div>
      <div className="field-box">
        <Controller
          name={`selectedSystems.${sectionIndex}.branchName`}
          control={control}
          rules={{ required: 'Pepeline release branch is required.' }}
          render={({ field, fieldState }) => (
            <>
              <label htmlFor={field.name} className="mb-2">
                Pipeline Release Branch
              </label>
              <Dropdown
                value={field.value}
                disabled={disabled}
                pt={{
                  root: {
                    className: 'min-w-full bg-transparent dark:border-neutral-70 dark:bg-transparent'
                  },
                  input: {
                    className: 'py-2'
                  }
                }}
                onChange={(e) => {
                  field.onChange(e.value)
                  setSelectedBranch(e.value)
                  setCommits(getCommits(selectedVersion, e.value))
                  setValue(`selectedSystems.${sectionIndex}.commitId`, null)
                  setValue(`selectedSystems.${sectionIndex}.entryScriptId`, null)
                }}
                className={classNames({ error: fieldState.error })}
                options={branchNames}
                placeholder="Select Branch"
                optionLabel="label"
                optionValue="value"
                emptyMessage="&nbsp;&nbsp;No Branch"
              />
            </>
          )}
        />
      </div>
      <div className="field-box">
        <Controller
          name={`selectedSystems.${sectionIndex}.commitId`}
          control={control}
          rules={{ required: 'Pipeline release is required.' }}
          render={({ field, fieldState }) => (
            <>
              <label htmlFor={field.name} className="mb-2">
                Pipeline Release Commit
              </label>
              <Dropdown
                value={field.value}
                disabled={disabled}
                pt={{
                  root: {
                    className: 'min-w-full bg-transparent dark:border-neutral-70 dark:bg-transparent'
                  },
                  input: {
                    className: 'py-2'
                  }
                }}
                onChange={(e) => {
                  field.onChange(e.value)
                  if (e.value) {
                    setScripts(commits.find((item) => item.commitId === e.value).deployStates)
                  }
                  setValue(`selectedSystems.${sectionIndex}.entryScriptId`, null)
                }}
                className={classNames({ error: fieldState.error })}
                options={commits}
                placeholder="Select Ppeline Release"
                optionLabel="commitId"
                optionValue="commitId"
                emptyMessage="&nbsp;&nbsp;No data"
              />
            </>
          )}
        />
      </div>
      <div className="field-box">
        <Controller
          name={`selectedSystems.${sectionIndex}.entryScriptId`}
          control={control}
          rules={{ required: 'Entry script is required.' }}
          render={({ field, fieldState }) => (
            <>
              <label htmlFor={field.name} className="mb-2">
                Entry Script
              </label>
              <Dropdown
                value={field.value}
                disabled={disabled}
                pt={{
                  root: {
                    className: 'min-w-full bg-transparent dark:border-neutral-70 dark:bg-transparent'
                  },
                  input: {
                    className: 'py-2'
                  }
                }}
                onChange={(e) => {
                  field.onChange(e.value)
                }}
                className={classNames({ error: fieldState.error })}
                options={scripts}
                placeholder="Select Script"
                optionLabel="name"
                optionValue="name"
                emptyMessage="&nbsp;&nbsp;No data"
              />
            </>
          )}
        />
      </div>
      <div className="field-box col-span-4">
        <Controller
          name={`selectedSystems.${sectionIndex}.notes`}
          control={control}
          disabled={disabled}
          render={({ field, fieldState }) => (
            <>
              <label htmlFor={field.name} className="mb-2">
                Notes
              </label>
              <InputText
                id={field.name}
                {...field}
                className={classNames({ error: fieldState.error })}
              />
            </>
          )}
        />
      </div>
    </div>
  )
}

export default MethodSetSection
