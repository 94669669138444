import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import {
  useCreateMethodologySet,
  useGetMethodologySets,
  useUpdateMethodologySet,
} from '../api/methodology-set-API'
import { CopyIcon, PlusIcon } from '../components/Icons'
import { protoService } from '../proto/ProtoService'
import { MethodologySet } from '../types/types'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { CheckboxStyle, DropdownStyle } from '../utils/styling-constants'
import 'react-json-view-lite/dist/index.css';
import SaveButton from '../components/buttons/SaveButton'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { Checkbox } from 'primereact/checkbox'
import { useGetDeploySets } from '../api/deploy-set-API'
import { DeploySet, SelectedSystem } from '@aero-platform/shared'
import MethodSetSection from '../components/MethodSetSection'
import { useRecoilValue } from 'recoil'
import { currentUserState } from '../utils/state-atoms'
import { cloneDeploySet, cloneSelectedSystem } from '../types/util'

type MethodForm = {
  name: string
  description: string
  selectedSystems: SelectedSystem[]
}

const MethodologySets: FC = () => {
  const [mode, setMode] = useState('')
  const [env, setEnv] = useState('prod')
  const [methodologySets, setMethodologySets] = useState<MethodologySet[]>([])
  const [currentMethodologySet, setCurrentMethodologySet] = useState<MethodologySet>()
  const [showConfig, setShowConfig] = useState(false)

  const [{ loading: loadingMethodologySets }, fetchMethodologySets] = useGetMethodologySets()
  const [{ loading: creating }, createMethodologySet] = useCreateMethodologySet()
  const [{ loading: updating }, updateMethodologySet] = useUpdateMethodologySet()

  const currentUser = useRecoilValue(currentUserState)

  const [, fetchDeploySets] = useGetDeploySets()

  const sections = ['ansa', 'starccmp', 'ensight', 'vtkslice']
  const [sectionVisibilities, setSectionVisibilities] = useState<Map<string, boolean>>(new Map([
    ['ansa', false],
    ['starccmp', false],
    ['ensight', false],
    ['vtkslice', false]
  ]))
  const [sectionIndexes, setSectionIndexes] = useState<Map<string, number>>(new Map([
    ['ansa', -1],
    ['starccmp', -1],
    ['ensight', -1],
    ['vtkslice', -1]
  ]))
  const [sectionNames] = useState<Map<string, string>>(new Map([
    ['ansa', 'ANSA'],
    ['starccmp', 'StarCCM'],
    ['ensight', 'Ensight'],
    ['vtkslice', 'MAV']
  ]))
  const [sectionDeploySets, setSectionDeploySets] = useState<Map<string, DeploySet[]>>(new Map())
  const [selectedSystems, setSelectedSystems] = useState<SelectedSystem[]>([])

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors }
  } = useForm<MethodForm>()

  useEffect(() => {
    fetchMethodologySets()
      .then((res) => {
        const { data } = res
        if (data) {
          const tmpList = data.protoBytesList.map((item: string) => {
            return protoService.deSerialize(item, 'methodologyset')
          })
          tmpList && setMethodologySets(tmpList)
        }
      })
      .catch((err) => {
        setMethodologySets([])
        toast.error('Error loading methodology sets.')
      })

    fetchDeploySets()
      .then((res) => {
        const { data } = res
        if (data) {
          const tmpList = data.protoBytesList.map((item: string) => {
            return protoService.deSerialize(item, 'deployset')
          })

          const newDsMap = new Map<string, DeploySet[]>(sectionDeploySets)
          sections.forEach((section) => {
            const dsBySection = tmpList.filter((item: DeploySet) => item.system === section)
            newDsMap.set(section, dsBySection)
          })
          setSectionDeploySets(newDsMap)
        }
      })
      .catch((err) => {
        toast.error('Error loading deploy sets.')
      })
  }, [])

  const onSubmit: SubmitHandler<MethodForm> = async (formData: MethodForm) => {
    console.log(formData)
    const newMethodSet = new MethodologySet()
    if (mode === 'edit') {
      newMethodSet._id = currentMethodologySet?._id as string
      newMethodSet.name = formData.name
    }

    newMethodSet.description = formData.description
    newMethodSet.selectedSystems = []
    sections.forEach((sectionKey) => {
      if (!sectionVisibilities.get(sectionKey)) { //skip if not not available/unchecked
        return
      }
      const selectedSystem = formData.selectedSystems[sectionIndexes.get(sectionKey)]
      selectedSystem.name = sectionKey
      const selectedDeploySet = sectionDeploySets.get(sectionKey).find((deploySet) => {
        return deploySet.vendorCompatibilityVersion === selectedSystem.vendorVersion && deploySet.branchName === selectedSystem.branchName
      })
      if (selectedDeploySet) {
        selectedSystem.deploySet = cloneDeploySet(selectedDeploySet)
      }
      newMethodSet.selectedSystems.push(cloneSelectedSystem(selectedSystem))
    })

    // set deployset id
    const dataToPost = protoService.encodePayload(newMethodSet, 'methodologyset')

    if (mode === 'new' || mode === 'copy') {
      createMethodologySet({
        data: dataToPost
      })
        .then((res) => {
          if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
            const tempMethodSet = protoService.deSerialize(res.data.protoBytesList[0], 'methodologyset')
            setMethodologySets([...methodologySets, tempMethodSet])
            setCurrentMethodologySet(tempMethodSet)
            handleItemSelected(tempMethodSet)
            setMode('edit')
          }
          toast.success('Methodology set created.')
        })
        .catch((err) => {
          console.error(err)
          toast.error('Error creating methodology set.')
        })
    } else if (mode === 'edit') {
      try {
        const res = await updateMethodologySet({
          id: currentMethodologySet?._id as string,
          data: dataToPost
        } as any)

        if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
          protoService.deSerialize(res.data.protoBytesList[0], 'methodologyset')
          const updatedMethods = methodologySets.map((item) => {
            if (item._id === currentMethodologySet?._id) {
              item.description = newMethodSet.description
              item.selectedSystems = newMethodSet.selectedSystems
            }
            return item
          })
          setMethodologySets(updatedMethods)
          setCurrentMethodologySet({
            ...currentMethodologySet,
            description: newMethodSet.description,
            selectedSystems: newMethodSet.selectedSystems
          } as MethodologySet)
        }

        toast.success('Methodology set updated.')
      } catch (err) {
        console.error(err)
        toast.error('Error updating methodology set.')
      }
    }
  }


  const handleCreateNewMethodologySet = () => {
    if (mode === 'new' || mode === 'copy') {
      toast.error('Cannot create a new methodology set while creating a new one.')
      return
    }

    const newIndexes = new Map(sectionIndexes)
    const newSectionVisibilities = new Map(sectionVisibilities)
    const newSelectedSystems: SelectedSystem[] = []
    sections.forEach((section, index) => {
      newIndexes.set(section, index)
      newSectionVisibilities.set(section, false)
      newSelectedSystems.push({} as SelectedSystem)
    })

    setSectionIndexes(newIndexes)
    setSelectedSystems(newSelectedSystems)
    setSectionVisibilities(newSectionVisibilities)

    reset()
    setMode('new')
    setValue('name', "")
    setValue('description', "")
    setCurrentMethodologySet({} as MethodologySet)

    sections.forEach((section) => {
      const secIndex = newIndexes.get(section)
      setValue(`selectedSystems.${secIndex}.name`,
        newSelectedSystems[secIndex].name)
      setValue(`selectedSystems.${secIndex}.notes`,
        newSelectedSystems[secIndex].notes)
      setValue(`selectedSystems.${secIndex}.vendorVersion`,
        newSelectedSystems[secIndex].vendorVersion)
      setValue(`selectedSystems.${secIndex}.branchName`,
        newSelectedSystems[secIndex].branchName)
      setValue(`selectedSystems.${secIndex}.commitId`,
        newSelectedSystems[secIndex].commitId)
      setValue(`selectedSystems.${secIndex}.entryScriptId`,
        newSelectedSystems[secIndex].entryScriptId)
    })

  }

  const handleItemSelected = (methodologySet) => {
    if (!methodologySet) {
      return
    }
    const newIndexes = new Map(sectionIndexes)
    const newSectionVisibilities = new Map(sectionVisibilities)
    const newSelectedSystems: SelectedSystem[] = []
    sections.forEach((section, index) => {
      newIndexes.set(section, index)
      newSectionVisibilities.set(section, false)
      newSelectedSystems.push({} as SelectedSystem)
    })

    methodologySet.selectedSystems.forEach((item) => {
      if (!sections.includes(item.name)) {
        return
      }
      const sectionKey = item.name
      const selectionIndex = newIndexes.get(sectionKey)
      newSectionVisibilities.set(sectionKey, !!item.vendorVersion)
      newSelectedSystems[selectionIndex] = {
        name: item.name,
        notes: item.notes,
        vendorVersion: item.vendorVersion,
        branchName: item.branchName,
        commitId: item.commitId,
        entryScriptId: item.entryScriptId
      } as SelectedSystem
    })

    setSectionIndexes(newIndexes)
    setSelectedSystems(newSelectedSystems)
    setSectionVisibilities(newSectionVisibilities)
    setCurrentMethodologySet(methodologySet)

    setMode('edit')

    reset()

    // init form data
    setValue('name', methodologySet.name)
    setValue('description', methodologySet.description)
    sections.forEach((section) => {
      const secIndex = newIndexes.get(section)
      setValue(`selectedSystems.${secIndex}.name`,
        newSelectedSystems[secIndex].name)
      setValue(`selectedSystems.${secIndex}.notes`,
        newSelectedSystems[secIndex].notes)
      setValue(`selectedSystems.${secIndex}.vendorVersion`,
        newSelectedSystems[secIndex].vendorVersion)
      setValue(`selectedSystems.${secIndex}.branchName`,
        newSelectedSystems[secIndex].branchName)
      setValue(`selectedSystems.${secIndex}.commitId`,
        newSelectedSystems[secIndex].commitId)
      setValue(`selectedSystems.${secIndex}.entryScriptId`,
        newSelectedSystems[secIndex].entryScriptId)
    })
  }

  const handleCopyMethodologySet = (e) => {
    if (mode === 'new' || mode === 'copy') {
      toast.error('Cannot duplicate while creating a new methodology set.')
      return
    }
    if (!currentMethodologySet._id) {
      toast.error('Please select a methodology set to duplicate.')
      return
    }

    setMode('copy')
    setValue('description', currentMethodologySet.description)
  }

  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full space-y-4">
      <div className="flex items-center justify-between pb-4">
        <h1 className="page-title">Methodology Sets</h1>
        {env === 'dev' &&
          <Button
            type="button"
            onClick={handleCreateNewMethodologySet}
            severity="secondary"
            className="icon-button hidden"
            disabled={mode === 'new' || mode === 'copy'}
            tooltip='Create New Methodology Set'
            tooltipOptions={{ position: 'bottom' }}
            data-pr-at='center+45 bottom'
          >
            <PlusIcon className="fill-white" />
          </Button>}
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full grid grid-1 gap-x-2 gap-y-4 mt-8 pb-10">
        <div className="flex items-center space-x-2">
          <div className="flex-grow basis-0 flex items-center space-x-2">
            <Dropdown
              filter
              loading={loadingMethodologySets}
              filterBy="name"
              filterMatchMode="contains"
              showFilterClear
              value={currentMethodologySet}
              emptyMessage="No MethodologySets Found"
              pt={{
                ...DropdownStyle,
                root: {
                  className: DropdownStyle.root.className + ' basis-0 flex-grow',
                },
              }}
              optionValue="_id"
              optionLabel="name"
              useOptionAsValue
              options={methodologySets}
              valueTemplate={(option) => {
                let value
                if (mode === 'new') {
                  value = 'New Methodology Set'
                } else if (option) {
                  value = option.name + (mode === 'copy' ? ' - Copy' : '') + (option.description ? ` (${option.description})` : '')
                } else {
                  value = (currentMethodologySet && currentMethodologySet.name) || 'Select Methodology Set'
                }
                return <div>{value}</div>
              }}
              itemTemplate={(option) => {
                return (
                  <div>{(option && option._id ? `${option.name}` : '')}{option.description ? ` (${option.description})` : ''}</div>
                )
              }}
              onChange={(e) => handleItemSelected(e.value)}
              placeholder="Select Methodology Set"
            />
          </div>
          <div className="flex items-center basis-[250px] justify-between space-x-2">
            {env === 'dev' &&
              <div className="flex items-center space-x-2 flex-shrink-0">
                <Button
                  type="button"
                  onClick={handleCopyMethodologySet}
                  className="icon-button"
                  tooltip='Duplicate Methodology Set'
                  tooltipOptions={{ position: "bottom" }}
                  data-pr-at='center+5 bottom'>
                  <CopyIcon className="fill-white w-4 h-4" />
                </Button>
                <SaveButton loading={creating || updating} title="Save Methodology Set" />
              </div>
            }
            {currentUser.role.roleId === 'admin' &&
              <div className="flex items-center space-x-2 flex-shrink-0 justify-end grow">
                <InputSwitch checked={env === 'dev'} onChange={
                  (e) => {
                    setEnv(e.value ? 'dev' : 'prod')
                  }}
                  pt={{
                    root: {
                      className: "!h-[24px] w-12"
                    },
                    slider: {
                      className: classNames('h-[24px] !w-12', { 'dark:!bg-gray-500': env === 'prod', 'dark:!bg-neutral-10': env === 'dev' })
                    }
                  }}
                />
                <label className="ml-1">Dev Mode</label>
              </div>}
          </div>
        </div>

        <div className="field-box col-span-1 mt-4">
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <label htmlFor={field.name} className="mb-2">
                  Description
                </label>
                <InputText
                  id={field.name}
                  disabled={creating || updating || !currentMethodologySet || env === 'prod'}
                  {...field}
                  className={classNames({ error: fieldState.error })}
                />
              </>
            )}
          />
        </div>

        {
          sections.map((sectionKey) => {
            return (
              <>
                <div className="field-box col-span-1 mt-4" key={sectionKey + '-checkbox'}>
                  <div className="flex-grow flex items-center">
                    <Checkbox
                      inputId={sectionKey}
                      disabled={creating || updating || !currentMethodologySet || env === 'prod'}
                      pt={CheckboxStyle}
                      checked={sectionVisibilities.get(sectionKey)}
                      onChange={(e) => {
                        sectionVisibilities.set(sectionKey, e.checked)
                        setSectionVisibilities(new Map(sectionVisibilities))
                      }}></Checkbox>
                    <label htmlFor={sectionKey}
                      className={classNames('ml-2 font-normal cursor-pointer', { 'text-gray-400': creating || updating || !currentMethodologySet || env === 'prod' })}>
                      {'Show ' + sectionNames.get(sectionKey)}
                    </label>
                  </div>
                </div>

                {currentMethodologySet && sectionVisibilities.get(sectionKey) && <MethodSetSection
                  key={sectionKey + '-form'}
                  sectionKey={sectionKey}
                  sectionIndex={sectionIndexes.get(sectionKey)}
                  deploySets={sectionDeploySets.get(sectionKey)}
                  sectionName={sectionNames.get(sectionKey)}
                  selectedSystem={selectedSystems[sectionIndexes.get(sectionKey)]}
                  control={control}
                  setValue={setValue}
                  disabled={creating || updating || env === 'prod'}
                  mode={mode}
                />}

              </>
            )
          })
        }

      </form>
    </div>
  )
}

export default MethodologySets
