import { atom } from 'recoil'
import {
  Geometry,
  Project,
  ProjectConfig,
  TyreSet,
  User,
  Vehicle,
  WorkflowSubmission,
  WorkflowSubmissionTemplate
} from '../types/types'
import {
  AttitudeSet,
  DomainSet,
  MethodologySet,
  SimulationSet
} from '@aero-platform/shared'

export const vehicleListState = atom<Vehicle[]>({
  key: 'VehicleList',
  default: []
})

const engineerDefault = new User()
engineerDefault._id = '1'
engineerDefault.firstName = 'John'
engineerDefault.lastName = 'Ding'

export const engineerListState = atom<User[]>({
  key: 'EngineerList',
  default: []
})

export const projectListState = atom<Project[]>({
  key: 'ProjectList',
  default: []
})

export const currentProjectState = atom<Project>({
  key: 'CurrentProject',
  default: undefined
})

export const geometryListState = atom<Geometry[]>({
  key: 'GeometryList',
  default: []
})

export const methodologySetListState = atom<MethodologySet[]>({
  key: 'MethodologySetList',
  default: []
})

export const attitudeSetListState = atom<AttitudeSet[]>({
  key: 'AttitudeSetList',
  default: []
})

export const simulationSetListState = atom<SimulationSet[]>({
  key: 'SimulationSetList',
  default: []
})

export const domainSetListState = atom<DomainSet[]>({
  key: 'DomainSetList',
  default: []
})

export const tyreSetListState = atom<TyreSet[]>({
  key: 'TyreSetList',
  default: []
})

export const projectConfigListState = atom<ProjectConfig[]>({
  key: 'ProjectConfigList',
  default: []
})

export const workflowTemplateListState = atom<WorkflowSubmissionTemplate[]>({
  key: 'WorkflowTemplateList',
  default: []
})

export const currentSubmissionState = atom<WorkflowSubmission | undefined>({
  key: 'CurrentSubmission',
  default: undefined
})

export const currentVehicleState = atom<Vehicle | undefined>({
  key: 'CurrentVehicle',
  default: undefined
})

export const currentUserState = atom<User | undefined>({
  key: 'CurrentUser',
  default: undefined
})

export const currentGMIDState = atom<string | undefined>({
  key: 'CurrentGMID',
  default: undefined
})

export const currentTeamState = atom<string>({
  key: 'CurrentTeam',
  default: undefined
})

export const teamListState = atom<string[]>({
  key: 'TeamList',
  default: []
})

export const lastTenRunsState = atom<WorkflowSubmission[]>({
  key: 'LastTenSubmissionList',
  default: []
})

export const showMySubmissionState = atom<boolean>({
  key: 'ShowMySubmissionOnly',
  default: true
})

export const isF1TeamState = atom<boolean>({
  key: 'ISF1Team',
  default: false
})

export const themeState = atom<string>({
  key: 'Theme',
  default: 'light'
})
