import { FC, useEffect, useState } from 'react'
import { useGetHeadlineData } from '../api/headline-data-API'

const HeadlineData: FC = () => {
  const [headlineData, setHeadlineData] = useState()
  const [, fetchHeadlineData] = useGetHeadlineData()

  useEffect(() => {
    fetchHeadlineData()
  }, [])

  return (
    <div className="basis-0 grow flex flex-col items-center justify-center">
      <h1 className="text-xl">Registration page</h1>
    </div>
  )
}

export default HeadlineData
