import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { DataTable, DataTableExpandedRows, DataTableFilterMeta, DataTableRowExpansionTemplate } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useGetDomainSet, useGetDomainSets, useUpdateDomainSet, useUpdateDomainState } from '../api/domain-set-API'
import { CollapseIcon, CopyIcon, EditIcon, ExpandIcon, PlusIcon } from '../components/Icons'
import { protoService } from '../proto/ProtoService'
import { LazyTableState, DomainSet } from '../types/types'
import { cloneDomainState, queryString2JsonString } from '../types/util'
import { DefaultPageSize } from '../utils/constants'
import { domainSetListState, currentTeamState } from '../utils/state-atoms'
import SaveButton from '../components/buttons/SaveButton'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { DropdownStyle, TabMenuStyle } from '../utils/styling-constants'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Column, ColumnBodyOptions } from 'primereact/column'
import { DomainState } from '@aero-platform/shared'
import IconButton from '../components/buttons/IconButton'
import DomainSetStateEditor from '../components/DomainSetStateEditor'
import { TabMenu } from 'primereact/tabmenu'
import { stat } from 'fs'

const defaultFilters: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  methodId: { value: null, matchMode: FilterMatchMode.EQUALS },
  status: { value: null, matchMode: FilterMatchMode.EQUALS }
}

const tabItems = [
  { label: 'Config' },
  { label: 'Logs' }
];

const DomainSets: FC = () => {
  const currentTeam = useRecoilValue(currentTeamState)
  const [mode, setMode] = useState('')
  const [editorMode, setEditorMode] = useState('')
  const [stateIndex, setStateIndex] = useState(0)
  const [showStateEditor, setShowStateEditor] = useState(false)
  const [{ loading: loadingDomains, data: domainSetsData }, fetchDomainSets] = useGetDomainSets()
  const [{ loading: loadingDomain, data: oneDomainSetData }, fetchOneDomainSet] = useGetDomainSet()
  const [{ loading: updating }, updateDomainSet] = useUpdateDomainSet()
  const [{ loading: updatingState }, updateDomainSetState] = useUpdateDomainState()

  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({})

  const [domainSets, setDomainSets] = useState<DomainSet[]>([])
  const [currentDomainSet, setCurrentDomainSet] = useState({} as DomainSet)
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [domainStates, setDomainStates] = useState<DomainState[]>([] as DomainState[])
  const [lazyState, setlazyState] = useState<LazyTableState>({
    first: 0,
    rows: DefaultPageSize,
    page: 1,
    sortField: 'name',
    sortOrder: -1,
    filters: defaultFilters,
    sumlvl: 'vehicleStatesList:0,geometryList:0,domainSetList:0,cfdSequenceList:0,method:0'
  })

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<DomainSet>()

  useEffect(() => {
    if (domainSetsData) {
      const tmpList = domainSetsData.protoBytesList.map((item: string) => {
        return protoService.deSerialize(item, 'domainset')
      })
      tmpList && setDomainSets(tmpList)
    } else {
      setDomainSets([])
    }
  }, [domainSetsData])

  useEffect(() => {
    if (oneDomainSetData) {
      console.log(oneDomainSetData)
      if (oneDomainSetData.protoBytesList && oneDomainSetData.protoBytesList.length) {
        const domainSet = protoService.deSerialize(oneDomainSetData.protoBytesList[0], 'domainset')
        setCurrentDomainSet(domainSet)
        setDomainStates(domainSet.domainStates.map((item) => {
          return { ...item } as DomainState
        }))
      } else {
        setDomainSets([])
        setDomainStates([])
        toast.error('Error loading domain set.')
      }
    }
  }, [oneDomainSetData])

  const makeQuery = (state) => {
    let query
    console.log(state)

    if (state.filters.name['value']) {
      const nameSearch = { $regex: state.filters.name['value'], $options: 'i' }
      const q = 'name=' + encodeURIComponent(JSON.stringify(nameSearch))
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.methodId['value']) {
      const q = 'methodId=' + state.filters.methodId['value']
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.status['value']) {
      const q = 'status=' + state.filters.status['value']
      query = query ? `${query}&${q}` : q
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${state.rows}` : `limit=${state.rows}`
    query = `${query}&skip=${state.first}&countTotal=true`
    if (state.sortField) {
      const sort = {
        [state.sortField]: state.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }

    return query
  }

  useEffect(() => {
    const query = makeQuery(lazyState)
    fetchDomainSets(query).catch((err) => {
      console.error(err)
      toast.error('Error loading domainSets.')
    })
  }, [lazyState])

  const handleCreateNewDomainSet = () => {
    if (mode === 'new') {
      return
    }
    reset()
    setMode('new')
    setValue('name', "")
    setValue('description', "")
    setCurrentDomainSet({} as DomainSet)
    setDomainStates([])
  }

  const handleCopyDomainSet = (e) => {
    if (mode === 'new') {
      toast.error('Cannot duplicate while creating a new domain set.')
      return
    }
    if (!currentDomainSet._id) {
      toast.error('Please select a domain set to duplicate.')
      return
    }

    setMode('new')
    setValue('name', currentDomainSet.name + ' (Copy)')
    setValue('description', currentDomainSet.description)
    setDomainStates(domainStates.map((item) => {
      return { ...item } as DomainState
    }))
  }

  const handleItemSelected = (domainSet) => {
    if (!domainSet) {
      return
    }
    fetchOneDomainSet(domainSet._id).catch((err) => {
      toast.error('Error loading domain set.')
    })

    setCurrentDomainSet(domainSet)
    setMode('edit')
    reset()
    setValue('name', domainSet.name)
    setValue('description', domainSet.description)
    if (domainSet.domainStates) {
      setDomainStates(domainSet.domainStates.map((item) => {
        return { ...item } as DomainState
      }))
    } else {
      setDomainStates([])
    }
  }

  const onSubmit: SubmitHandler<DomainSet> = async (formData: DomainSet) => {
    if (!currentDomainSet._id) {
      return
    }
    const newDomainSet = new DomainSet(formData)
    newDomainSet._id = currentDomainSet._id
    newDomainSet.raceTeamId = currentTeam
    const dataToPost = protoService.encodePayload(newDomainSet, 'domainset')
    try {
      await updateDomainSet({
        id: currentDomainSet._id,
        data: dataToPost
      } as any)
      setDomainSets(
        domainSets.map((item) => {
          if (item._id === currentDomainSet._id) {
            return newDomainSet
          } else {
            return item
          }
        })
      )
      setCurrentDomainSet(newDomainSet)
      toast.success('Domain set updated.')
    } catch (err) {
      console.error(err)
      toast.error('Error updating domain set.')
    }
  }

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name]?.message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const stateColumns = [
    {
      header: 'Name',
      field: 'name',
      filter: true,
      headerStyle: {},
      bodyStyle: {},
      bodyClassName: 'id-cell max-w-[200px] w-[200px]',
      headerClassName: 'max-w-[200px]',
      sortable: true,
    },
    {
      header: 'Description/File Reference',
      field: 'description',
      body: (rowData: DomainState) => {
        const fileRef = rowData.fileRef
        return fileRef ? (
          <div className='flex flex-col items-stretch'>
            {rowData.description && <div>
              {rowData.description}
            </div>}
            <div className="w-full grid grid-cols-12">
              <div className="col-span-12 grid grid-cols-12">
                <div className="col-span-1 font-semibold">Name</div>
                <div className="col-span-11">{fileRef.name}</div>
                <div className="col-span-1 font-semibold">Path</div>
                <div className="col-span-11">{fileRef.path}</div>
                <div className="col-span-1 font-semibold">Version</div>
                <div className="col-span-11">{fileRef.version}</div>
              </div>
            </div>
          </div>
        ) : null
      }
    },
    {
      header: 'WT',
      field: 'WTDomain',
      headerClassName: 'max-w-[100px]',
      bodyClassName: 'max-w-[100px] w-[80px]',
      body: (rowData: DomainState) => {
        return rowData.WTDomain ? 'Yes' : 'No'
      }
    },
    {
      header: 'MMSLocation',
      field: 'MMSLocation',
      headerClassName: 'max-w-[250px]',
      bodyClassName: 'max-w-[250px] w-[250px]',
      body: (rowData: DomainState) => {
        if (rowData.MMSLocation && rowData.MMSLocation.length > 0) {
          return (
            <div className="flex items-center space-x-2">
              <span className="font-semibold">X: </span>
              <span>{rowData.MMSLocation[0].toFixed(1)}, </span>
              <span className="font-semibold">Y: </span>
              <span>{rowData.MMSLocation[1].toFixed(1)}, </span>
              <span className="font-semibold">Z: </span>
              <span>{rowData.MMSLocation[2].toFixed(1)}</span>
            </div>
          )
        }
        return ''
      }
    },
  ]

  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full space-y-4">
      <div className="flex items-center justify-between pb-4">
        <h1 className="page-title">Domain Sets</h1>
        <Button
          type="button"
          onClick={handleCreateNewDomainSet}
          severity="secondary"
          className="icon-button !hidden"
          disabled={mode === 'new'}
          tooltip='Create New Domain Set'
          tooltipOptions={{ position: 'bottom' }}
          data-pr-at='center+45 bottom'
        >
          <PlusIcon className="fill-white" />
        </Button>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full grid grid-cols-2 gap-x-2 gap-y-4 mt-8">
        <div className="flex items-center col-span-2 space-x-2">
          <Dropdown
            filter
            loading={loadingDomains}
            filterBy="name"
            filterMatchMode="contains"
            showFilterClear
            onFilter={(e) => {
              setlazyState({
                ...lazyState,
                filters: {
                  ...lazyState.filters,
                  name: { value: e.filter, matchMode: FilterMatchMode.CONTAINS }
                }
              })
            }}
            value={currentDomainSet}
            emptyMessage="No DomainSets Found"
            pt={{
              ...DropdownStyle,
              root: {
                ...DropdownStyle.root,
                className: 'min-w-[30%] max-w-[50%]'
              },
            }}
            optionValue="_id"
            optionLabel="name"
            useOptionAsValue
            options={domainSets}
            valueTemplate={(option) => {
              let value
              if (mode === 'new') {
                value = 'New DomainSet'
              } else if (option) {
                value = option.name
              } else {
                value = (currentDomainSet && currentDomainSet.name) || 'Select Domain Set'
              }
              return <div>{value}</div>
            }}
            itemTemplate={(option) => <div>{option && option._id ? option.name : ''}</div>}
            onChange={(e) => handleItemSelected(e.value)}
            placeholder="Select Domain Set"
          />
          <Button
            type="button"
            onClick={handleCopyDomainSet}
            className="icon-button !hidden"
            tooltip='Duplicate Domain Set'
            tooltipOptions={{ position: "bottom" }}
            data-pr-at='center+5 bottom'>
            <CopyIcon className="fill-white w-4 h-4" />
          </Button>
          <SaveButton loading={updating} title="Save Domain Set" />
        </div>
        <div className="col-span-2">
          <TabMenu model={tabItems}
            onChange={(e) => console.log(e)}
            activeIndex={currentTabIndex}
            pt={TabMenuStyle}
            onTabChange={(e) => setCurrentTabIndex(e.index)} />
          <div className='min-h-4'> </div>
          {currentDomainSet && currentTabIndex === 0 &&
            <>
              <section className="grid grid-cols-2 space-x-2">
                <div className="field-box">
                  <Controller
                    name="name"
                    control={control}
                    disabled={updating || !currentDomainSet._id}
                    rules={{ required: 'Name is required.' }}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className="mb-2">
                          Name
                        </label>
                        <InputText
                          id={field.name}
                          {...field}
                          className={classNames({ error: fieldState.error })}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>

                <div className="field-box">
                  <Controller
                    name="description"
                    control={control}
                    disabled={updating || !currentDomainSet._id}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className="mb-2">
                          Description
                        </label>
                        <InputText
                          id={field.name}
                          {...field}
                          className={classNames({ error: fieldState.error })}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              </section>
              <div className='font-semibold'>States</div>
              <section className="col-span-2">
                <DataTable
                  value={domainStates}
                  loading={loadingDomain}
                  dataKey="name"
                  filterDisplay="row"
                  tableClassName="domain-set-states"
                  stripedRows
                  sortField='vehicleId'
                  pt={{
                    bodyRow: {
                      className: 'table-row'
                    },
                    rowExpansion: {
                      className: 'pt-0'
                    }
                  }}>
                  {stateColumns.map((item) => {
                    return (
                      <Column
                        key={item.field}
                        field={item.field}
                        header={item.header}
                        showFilterMenu={false}
                        sortable={item.sortable}
                        headerStyle={item.headerStyle}
                        headerClassName={item.headerClassName}
                        bodyStyle={item.bodyStyle}
                        body={item.body}
                        bodyClassName={item.bodyClassName}></Column>
                    )
                  })}
                  <Column
                    body={(rowData: DomainState, options: ColumnBodyOptions) => {
                      return (
                        <div className="flex items-center space-x-1 justify-end">
                          <IconButton
                            severity="secondary"
                            icon={<EditIcon className="h-4 w-4 fill-white stroke-transparent" />}
                            onClick={() => {
                              setStateIndex(options.rowIndex)
                              setEditorMode('edit')
                              setShowStateEditor(true)
                            }}
                          >
                          </IconButton>
                        </div>
                      )
                    }}
                    headerClassName="one-col-editor-header editor-header !w-12 max-w-12"
                    bodyClassName="!pr-0 !max-w-12"
                    pt={{
                      headerContent: {
                        className: 'pr-0 min-w-full'
                      },
                      headerTitle: {
                        className: 'pr-0 w-full flex items-center'
                      },
                    }}
                    bodyStyle={{ textAlign: 'center', padding: '12px 8px' }}></Column>
                </DataTable>
              </section>
            </>
          }
        </div>
      </form >

      {showStateEditor &&
        <DomainSetStateEditor
          mode={editorMode}
          stateIndex={stateIndex}
          states={domainStates}
          saving={updatingState}
          onConfirm={async (stateData, stateMode) => {
            let newState = new DomainState()
            newState.name = domainStates[stateIndex].name
            newState.description = stateData.description
            newState.MMSLocation = [...stateData.MMSLocation]
            try {
              await updateDomainSetState({
                id: currentDomainSet._id,
                data: protoService.encodePayload(newState, 'domainstate')
              } as any)
              setShowStateEditor(false)
              fetchOneDomainSet(currentDomainSet._id).catch((err) => {
                toast.error('Error loading domain set.')
              })
              toast.success('Domain state updated.')
            } catch (err) {
              console.error(err)
              toast.error('Error updating domain state.')
            }
          }}
          onCancel={() => {
            setShowStateEditor(false)
          }}
        />}

    </div >
  )
}

export default DomainSets
