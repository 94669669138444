
const Footer = () => {
  const buildEnv = process.env.REACT_APP_BUILD_ENV
  const buildNum = process.env.REACT_APP_BUILD_NUM
  const buildTime = process.env.REACT_APP_BUILD_TIME

  return (
    <footer className="relative px-4 h-12 min-w-full flex items-center bg-gm-blue dark:bg-neutral-80 text-neutral-10 justify-between">
      <div className="flex ">GM Motorsports Aero version 1.0</div>
      {buildEnv && <div>
        Build: {buildEnv}-{buildNum} @ {buildTime}
      </div>}
      <div className="">
        ©General Motors 2023. All Rights Reserved.
      </div>
    </footer>
  )
}

export default Footer
