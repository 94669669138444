import { Locale } from '@aero-platform/shared'
import format from 'date-fns/format'


export const formatDateWithLocale = (date: Date | number, locale: Locale) => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    date = new Date(date)
  }

  const langCode = locale?.language || navigator.language || document.documentElement.lang || 'en-US'
  const timeZone = locale?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone

  try {
    return date.toLocaleString(langCode, {
      timeZone,
    })
  } catch (error) {
    console.error('Error formatting date:', error)
    return date.toLocaleString()
  }
}

export const formatDate = (date: Date) => {
  const langCode = navigator.language || document.documentElement.lang

  if (langCode === 'en-US') {
    return format(date, 'MM/dd/yyyy')
  } else {
    return format(date, 'dd/MM/yyyy')
  }
}

export const formatDateNumber = (date: number | string, formatStr?: string) => {
  if (!date) {
    return ''
  }
  const dt = new Date(date)
  const langCode = navigator.language || document.documentElement.lang

  if (langCode === 'en-US') {
    return format(dt, formatStr || 'MM/dd/yyyy')
  } else {
    return format(dt, formatStr || 'dd/MM/yyyy')
  }
}

export const moveArrayItem = (arr: any[], from: number, to: number) => {
  const newArr = [...arr]
  if (from === to) {
    return newArr
  }
  const ele = newArr[from]
  newArr[from] = undefined
  newArr.splice(to, 0, ele)
  return newArr.filter((item) => item !== undefined)
}
