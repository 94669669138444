import { Button } from 'primereact/button'
import { confirmDialog } from 'primereact/confirmdialog'
import { InputText } from 'primereact/inputtext'
import { FC, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useUpdateUser } from '../api/user-API'
import { protoService } from '../proto/ProtoService'
import { RescaleToken, User } from '../types/types'
import { cloneUser } from '../types/util'
import { CurrentUserKey } from '../utils/constants'
import { classNames } from 'primereact/utils'

type TokenItem = {
  token: string
}

type UserProfile = {
  firstName: string
  lastName: string
  tokens: TokenItem[]
}
const Profile: FC = () => {
  const [userProfile, setUserProfile] = useState<User>()
  const [{ loading: updatingUserInfo }, updateUser] = useUpdateUser('')

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<UserProfile>({
    defaultValues: {
      firstName: '',
      lastName: '',
      tokens: [{ token: '' }]
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tokens'
  })

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name].message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const onSubmit: SubmitHandler<UserProfile> = async (formData: UserProfile) => {
    console.log(formData)
    const newUserProfile = new User({
      firstName: formData.firstName,
      lastName: formData.lastName,
      rescaleTokens: formData.tokens.map((item) => {
        return new RescaleToken({ token: item.token })
      })
    })

    try {
      const dataToPost = protoService.encodePayload(newUserProfile, 'user')
      const res = await updateUser({
        url: `/Users/${userProfile?._id}`,
        data: dataToPost
      })
      console.log(res)

      const updatedProfile: User = cloneUser(userProfile as User)
      updatedProfile.firstName = formData.firstName
      updatedProfile.lastName = formData.lastName
      updatedProfile.rescaleTokens = newUserProfile.rescaleTokens

      localStorage.setItem(CurrentUserKey, JSON.stringify(updatedProfile))
      toast.success('profile saved.')
    } catch (err) {
      toast.error('Error saving profile.')
      console.error(err)
    }
  }

  const handleRemoveToken = (index: number) => {
    const formValues = getValues()
    if (formValues.tokens.length > 1 && !formValues.tokens[index].token) {
      remove(index)
      return
    }
    console.log(confirmDialog)
    confirmDialog({
      message: 'Are you sure you want to delete the token?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      rejectClassName: 'bg-gray-500 text-white',
      accept: () => {
        const formValues = getValues()
        if (formValues.tokens) {
          if (formValues.tokens.length > 1) {
            remove(index)
          } else if (formValues.tokens.length === 1) {
            setValue('tokens', [{ token: '' }])
          } else {
            setValue('tokens', [])
          }
        } else {
          setValue('tokens', [])
        }
      }
    })
    alert(1)
  }

  useEffect(() => {
    const userStr = localStorage.getItem(CurrentUserKey)
    if (userStr) {
      const userObj: User = JSON.parse(userStr)
      setUserProfile(userObj)
      reset({
        lastName: userObj.lastName,
        firstName: userObj.firstName,
        tokens: userObj.rescaleTokens.map((item) => {
          return {
            token: item.token
          }
        })
      })
    }
    console.log(userStr)
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col items-stretch">
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center justify-between pb-4 col-span-2 mt-2">
          <span className="text-dark-blue dark:text-white font-bold">User Profile</span>
          <Button type="submit" label="Save Profile" loading={updatingUserInfo} />
        </div>
        <div className="flex flex-col items-stretch col-span-1">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: 'First Name is required.' }}
            defaultValue={userProfile && userProfile.firstName}
            render={({ field, fieldState }) => (
              <>
                <label htmlFor={field.name} className="mb-2">
                  First Name
                </label>
                <InputText
                  id={field.name}
                  value={field.value}
                  className={classNames({ error: fieldState.error })}
                  onChange={(e) => field.onChange(e.target.value)}
                />
                {getFormErrorMessage(field.name)}
              </>
            )}
          />
        </div>
        <div className="flex flex-col items-stretch col-span-1">
          <Controller
            name="lastName"
            control={control}
            rules={{ required: 'Last Name is required.' }}
            defaultValue={userProfile && userProfile.firstName}
            render={({ field, fieldState }) => (
              <>
                <label htmlFor={field.name} className="mb-2">
                  Last Name
                </label>
                <InputText
                  id={field.name}
                  value={field.value}
                  className={classNames({ error: fieldState.error })}
                  onChange={(e) => field.onChange(e.target.value)}
                />
                {getFormErrorMessage(field.name)}
              </>
            )}
          />
        </div>
        <div className="flex items-center justify-between col-span-2">
          <span className="mb-2">Rescale Tokens:</span>
          <Button
            label="+ Add a Token"
            type="button"
            onClick={() => {
              append({ token: '' })
            }}
          ></Button>
        </div>
        {fields.map((tokenField, index) => {
          return (
            <div className="flex col-span-2 space-x-4 justify-start" key={tokenField.id}>
              <Controller
                name={`tokens.${index}.token`}
                control={control}
                rules={{ required: 'Token is required.' }}
                defaultValue={userProfile && userProfile.firstName}
                render={({ field, fieldState }) => (
                  <>
                    <div className="pt-3">
                      <label htmlFor={field.name}>{`Token #${index + 1}. `}</label>
                    </div>
                    <div className="basis-0 grow flex flex-col items-stretch">
                      <InputText
                        id={field.name}
                        value={field.value}
                        className={classNames({ error: fieldState.error })}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                      {getFormErrorMessage(field.name)}
                    </div>
                  </>
                )}
              />
              <div>
                <Button
                  icon="pi pi-trash"
                  severity="danger"
                  type="button"
                  onClick={() => handleRemoveToken(index)}
                  tooltip='Delete Token'
                  tooltipOptions={{ position: 'bottom' }}
                  data-pr-at='center+15 bottom'
                ></Button>
              </div>
            </div>
          )
        })}
      </div>
    </form>
  )
}

export default Profile
