import { IPublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { PrimeReactProvider } from 'primereact/api'
import { ConfirmDialog } from 'primereact/confirmdialog'
import Tailwind from 'primereact/passthrough/tailwind'

import { classNames } from 'primereact/utils'
import { FC, useEffect } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useRecoilState, useSetRecoilState } from 'recoil'
import './App.css'
import { useGetProjectConfigs } from './api/project-config-API'
import { useGetSubmissions } from './api/submission-API'
import { useGetWorkflowTemplates } from './api/workflow-template-API'
import { useGetTyreSets } from './api/tyreset-API'
import { useGetVehicles } from './api/vehicle-API'
import { protoService } from './proto/ProtoService'
import { Routing } from './routing'
import { GMMSRaceTeamKey, simpleRunProjection } from './utils/constants'
import { appEventInstance } from './utils/event'
import {
  attitudeSetListState,
  currentTeamState,
  domainSetListState,
  engineerListState,
  lastTenRunsState,
  methodologySetListState,
  projectConfigListState,
  simulationSetListState,
  workflowTemplateListState,
  themeState,
  tyreSetListState,
  vehicleListState,
} from './utils/state-atoms'
import { BreadcrumbProvider } from './contexts/breadcrumbContexts/BreadcrumbContext'
import { useGetEngineers } from './api/user-API'
import { useGetAttitudeSets } from './api/attitude-set-API'
import { useGetDomainSets } from './api/domain-set-API'
import { useGetSimulationSets } from './api/simulation-set-API'
import { useGetMethodologySets } from './api/methodology-set-API'

// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/samples/msal-react-samples

type AppProps = {
  pca?: IPublicClientApplication
}

const App: FC<AppProps> = ({ pca }) => {
  const navigate = useNavigate()

  const setVehicles = useSetRecoilState(vehicleListState)
  const setMethodologySets = useSetRecoilState(methodologySetListState)
  const setTyreSets = useSetRecoilState(tyreSetListState)
  const setAttitudeSets = useSetRecoilState(attitudeSetListState)
  const setDomainSets = useSetRecoilState(domainSetListState)
  const setSimalationSets = useSetRecoilState(simulationSetListState)
  const setSubmissionTemplates = useSetRecoilState(workflowTemplateListState)
  const setProjectConfig = useSetRecoilState(projectConfigListState)
  const setCurrentTeam = useSetRecoilState(currentTeamState)
  const setEngineers = useSetRecoilState(engineerListState)
  const setLastRuns = useSetRecoilState(lastTenRunsState)
  const [theme, setTheme] = useRecoilState(themeState)
  const [{ }, fetchEngineers] = useGetEngineers()

  const [{ }, fetchVehicles] = useGetVehicles()
  const [{ }, fetchMethodologySets] = useGetMethodologySets()
  const [{ }, fetchProjectConfigs] = useGetProjectConfigs()
  const [{ }, fetchSubmissionTemplates] = useGetWorkflowTemplates()
  const [{ }, fetchLatestRuns] = useGetSubmissions()
  const [{ }, fetchTyreSets] = useGetTyreSets()
  const [{ }, fetchAttitudeSets] = useGetAttitudeSets()
  const [{ }, fetchDomainSets] = useGetDomainSets()
  const [{ }, fetchSimulationSets] = useGetSimulationSets()


  const loadTeamData = () => {
    fetchVehicles().then((vehicleData) => {
      if (vehicleData.data) {
        try {
          const vehiclesList = protoService.decodeProtoResponse(vehicleData.data, 'vehicle')
          setVehicles(vehiclesList)
        } catch (err) {
          console.error(err)
          toast.error('Error decoding vehicle data.')
        }
      }
    }).catch((err) => {
      console.error(err)
      toast.error('Error loading vehicle list.')
    })

    fetchEngineers().then((engData) => {
      if (engData.data) {
        const engs = protoService.decodeProtoResponse(engData.data, 'user')
        setEngineers(engs)
      }
    }).catch((err) => {
      console.error(err)
      toast.error('Error loading engineers list.')
    })

    fetchMethodologySets().then((methodologySetData) => {
      if (methodologySetData.data) {
        try {
          const methodSets = protoService.decodeProtoResponse(methodologySetData.data, 'methodologyset')
          setMethodologySets(methodSets)
        } catch (err) {
          console.error(err)
          toast.error('Error decoding methodology set data.')
        }
      }
    }).catch((err) => {
      console.error(err)
      toast.error('Error loading methodology sets.')
    })

    fetchTyreSets().then((tyreSetData) => {
      if (tyreSetData.data) {
        try {
          const tyreSets = protoService.decodeProtoResponse(tyreSetData.data, 'tyreset')
          setTyreSets(tyreSets)
        } catch (err) {
          console.error(err)
          toast.error('Error decoding tyre set data.')
        }
      }
    }).catch((err) => {
      console.error(err)
      toast.error('Error loading tyre sets.')
    })

    fetchAttitudeSets().then((attitudeSetData) => {
      if (attitudeSetData.data) {
        try {
          const attitudeSets = protoService.decodeProtoResponse(attitudeSetData.data, 'attitudeset')
          setAttitudeSets(attitudeSets)
        } catch (err) {
          console.error(err)
          toast.error('Error decoding attitude set data.')
        }
      }
    }).catch((err) => {
      console.error(err)
      toast.error('Error loading attitude sets.')
    })

    fetchDomainSets().then((domainSetData) => {
      if (domainSetData.data) {
        try {
          const tyreSets = protoService.decodeProtoResponse(domainSetData.data, 'domainset')
          setDomainSets(tyreSets)
        } catch (err) {
          console.error(err)
          toast.error('Error decoding domain set data.')
        }
      }
    }).catch((err) => {
      console.error(err)
      toast.error('Error loading domain sets.')
    })

    fetchSimulationSets().then((simulationSetData) => {
      if (simulationSetData.data) {
        try {
          const simulationSets = protoService.decodeProtoResponse(simulationSetData.data, 'simulationset')
          setSimalationSets(simulationSets)
        } catch (err) {
          console.error(err)
          toast.error('Error decoding simulation set data.')
        }
      }

    }).catch((err) => {
      console.error(err)
      toast.error('Error loading simulation sets.')
    })

    fetchProjectConfigs().then((projectConfigData) => {
      if (projectConfigData.data) {
        try {
          const templateList = protoService.decodeProtoResponse(projectConfigData.data, 'projectconfig')
          setProjectConfig(templateList)
        } catch (err) {
          console.error(err)
          toast.error('Error decoding project config data.')
        }
      }
    }).catch((err) => {
      console.error(err)
      toast.error('Error loading project configs.')
    })

    fetchSubmissionTemplates().then((submissionTemplateData) => {
      if (submissionTemplateData.data) {
        try {
          const templateList = protoService.decodeProtoResponse(
            submissionTemplateData.data,
            'workflowsubmissiontemplate'
          )
          setSubmissionTemplates(templateList)
        } catch (err) {
          console.error(err)
          toast.error('Error decoding submission template data.')
        }
      }

    }).catch((err) => {
      console.error(err)
      toast.error('Error loading submission templates.')
    })

    fetchLatestRuns(`limit=10&proj=${simpleRunProjection}`).then((latestRunData) => {
      if (latestRunData.data) {
        if (latestRunData.data.protoBytesList.length) {
          const runs = latestRunData.data.protoBytesList.map((item: string) => {
            const submission = protoService.deSerialize(item, 'workflowsubmission')
            return submission
          })
          setLastRuns(runs)
        }
      }

    }).catch((err) => {
      console.error(err)
      toast.error('Error loading latest 10 runs.')
    })
  }

  useEffect(() => {
    console.log('App loaded.')

    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    const savedTheme = localStorage.getItem('theme')
    let tmpTheme = 'light'
    if (savedTheme) {
      tmpTheme = savedTheme
      setTheme(savedTheme)
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      tmpTheme = 'dark'
    }
    setTheme(tmpTheme)

    if (tmpTheme === 'dark') {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }

    // Whenever the user explicitly chooses light mode
    localStorage.theme = 'light'

    // Whenever the user explicitly chooses dark mode
    localStorage.theme = 'dark'

    // Whenever the user explicitly chooses to respect the OS preference
    localStorage.removeItem('theme')

    const savedTeam = localStorage.getItem(GMMSRaceTeamKey)
    savedTeam && setCurrentTeam(savedTeam)

    appEventInstance.addEventListener('error401', () => {
      console.log('Error 401 event received.')
      navigate('/auth/login')
    })

    appEventInstance.addEventListener('errorNetwork', () => {
      toast.error('Network error. Please check your connection.')
    })

    appEventInstance.addEventListener('userLoggedIn', loadTeamData)

    appEventInstance.addEventListener('teamChanged', () => {
      loadTeamData()
      navigate('/vehicles?ts=' + new Date().getTime())
    })

    return () => {
      appEventInstance.removeEventListener('error401', () => {
        console.log('Error 401 event listener removed.')
      })
      appEventInstance.removeEventListener('errorNetwork', () => {
        console.log('Error network event listener removed.')
      })
      appEventInstance.removeEventListener('userLoggedIn', () => {
        console.log('userLoggedIn event listener removed.')
      })
      appEventInstance.removeEventListener('teamChanged', () => {
        console.log('teamChanged event listener removed.')
      })
    }
  }, [])

  Tailwind.button = {
    root: (pt) => {
      return {
        className: classNames(
          'px-4 py-1.5 rounded-[5px] text-white flex items-center justify-center font-semibold',
          {
            '!bg-transparent': pt!.props.link,
            'bg-dark-blue': !pt!.props.severity,
            'dark:bg-gm-aqua-dark': !pt!.props.severity,
            'bg-gm-blue': pt!.props.severity === 'secondary',
            'bg-error': pt!.props.severity === 'danger',
            'bg-gm-aqua-dark': pt!.props.severity === 'info'
          }
        )
      }
    },
    label: (pt) => {
      return {
        className: classNames('font-semibold', {
          '!text-dark-blue': pt!.props.link,
          'text-white': pt!.props.severity === null,
          'text-gm-blue': pt!.props.severity === 'secondary',
          'text-gray-700': pt!.props.severity === 'info'
        })
      }
    }
  }

  Tailwind.inputtext = {
    root: (pt) => {
      const props = pt!.props
      const context = pt!.context
      return {
        className: classNames(
          'm-0 py-2',
          'text-gray-600 dark:text-white/80 bg-white dark:bg-transparent border border-gray-400 dark:border-neutral-70 transition-colors duration-200 appearance-none rounded-lg',
          {
            'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]':
              !context.disabled,
            'opacity-60 select-none pointer-events-none cursor-default': context.disabled
          },
          {
            'text-lg px-4 py-4': props.size === 'large',
            'text-xs px-2 py-2': props.size === 'small',
            'p-3 text-base': !props.size || typeof props.size === 'number'
          }
        )
      }
    }
  }

  return (
    <>
      <Toaster position="top-center" />
      <MsalProvider instance={pca as IPublicClientApplication}>
        <PrimeReactProvider value={{ unstyled: true, pt: Tailwind }}>
          <ConfirmDialog />
          <Routing />
        </PrimeReactProvider>
      </MsalProvider>
    </>
  )
}

export default App
